import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import CurrencyField from '../../elements/form/CurrencyField';
import FormButton from '../../elements/form/FormButton';
import SelectField from '../../elements/form/SelectField'
import TextField from '../../elements/form/TextField';

const NewTicketForm = ({forEvent, ticketPayload, setTicketPayload}) => {
    const dispatch = useDispatch()
    const eventsState = useSelector(state => state.events)
    const ticketsState = useSelector(state => state.tickets)
    const [validationErrors, setValidationErrors] = useState({});
    const [ticketOwner, setTicketOwner] = useState({});

    const validateForm = () => {
        let errors = {}

        if(!ticketPayload.event || ticketPayload.event === '') {
            errors.event = true
        }
        
        // if (!Object.values(validateOwnerForm()).includes(true)) {
        //     let owners = [] 
        //     if(ticketPayload.ticketOwners && ticketPayload.ticketOwners.includes(ticketOwner)) {
        //         return
        //     }
        //     if(ticketPayload.ticketOwners) {
        //         owners = [...ticketPayload.ticketOwners, ticketOwner]
        //     }
        //     setTicketPayload({...ticketPayload, ...{ticketOwners: owners}})
        // }

        if(!ticketPayload.ticketOwners || ticketPayload.ticketOwners.length === 0) {
            errors.ticketOwners = true
        }

        setValidationErrors(errors)
        console.log(errors)
        return errors
    }

    const validateOwnerForm = () => {
        let errors = {}

        if(!ticketOwner.name || ticketOwner.name === '') {
            errors.name = true
        }

        if(!ticketOwner.email || ticketOwner.email === '') {
            errors.email = true
        }

        if(!ticketOwner.phone || ticketOwner.phone === '') {
            errors.phone = true
        }

        setValidationErrors(errors)
        return errors
    }

    const validatePaymentForm = () => {
         
    }

    const addOwner = () => {
        if (Object.values(validateOwnerForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please fill in all required field(s) highlighted'
                }}}
            })
            return
        }

        for (let index = 0; index < ticketPayload.ticketOwners.length; index++) {
            if(ticketPayload.ticketOwners[index].email === ticketOwner.email) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'This email address is already in use by one of the listed owners of this ticket.'
                    }}}
                })
                return
            }
        }

        let owners = [] 
        if(ticketPayload.ticketOwners) {
            owners = [...ticketPayload.ticketOwners, ticketOwner]
        }
        setTicketPayload({...ticketPayload, ...{ticketOwners: owners}})
        setTicketOwner(null)
    }

    const [transactionDetails, setTransactionDetails] = useState({
        paymentFor: 'TICKET',
        processor: 'CASHIER'
    });

    const paymentChannels = [
        {
            label: 'Cash Payment',
            value: 'CASH'
        },
        {
            label: 'POS',
            value: 'POS'
        },
        {
            label: 'Bank Transfer',
            value: 'CASH'
        },
    ]
    const [eventPrice, setEventPrice] = useState(null);
    const [paymentComputed, setPaymentComputed] = useState(false);
    
    const computePayment = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please fill in all required field(s) highlighted'
                }}}
            })
            return
        }

        setPaymentComputed(true)
    }

    return (
        <>
            <div className='my-8 w-full'>
                <SelectField
                    selectOptions = {eventsState.events.events}
                    inputLabel = "Event"
                    titleField = "name"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event"
                    preSelected={ticketPayload.event || ''}
                    preSelectedLabel='_id'
                    hasError = {validationErrors && validationErrors.event}
                    disabled={forEvent && forEvent === true ? true : false}
                    returnFieldValue={(value)=>{
                        setEventPrice(null)
                        setTimeout(() => {
                            setEventPrice(value.ticketPrice)
                        }, 50);
                        setPaymentComputed(false)
                        setTicketPayload({...ticketPayload, ...{event: value._id}})}
                    }
                />
            </div> 

            <div className={`p-5 bg-white border rounded-md ${validationErrors.ticketOwners  ? 'border-red-400' : 'border-gray-200'}`}>
                <p className='text-black text-sm font-medium'>Ticket Owners</p>
                <p className='text-black text-sm font-light mb-2'>Fill in the details of the owner(s) of this ticket below. Click on "Add this user" after filling the details of each owner</p>
                {validationErrors.ticketOwners && <p className='text-red-500 text-sm font-light mb-8'>Please add at least one owner for this ticket</p>}

                {ticketPayload?.ticketOwners?.length > 0 && <div></div>}

                <div className='my-4 w-full'>
                    <TextField
                        inputLabel="Full name" 
                        fieldId="owner-name" 
                        inputType="text" 
                        hasError={validationErrors.name} 
                        returnFieldValue={(value)=>{setTicketOwner({...ticketOwner, ...{name: value}})}}
                    />
                </div>  
                <div className='w-full flex gap-x-4 justify-between'>
                    <div className='my-4 w-full'>
                        <TextField
                            inputLabel="Email address" 
                            fieldId="owner-email" 
                            inputType="email" 
                            hasError={validationErrors.email} 
                            returnFieldValue={(value)=>{setTicketOwner({...ticketOwner, ...{email: value}})}}
                        />
                    </div>  
                    <div className='my-4 w-full'>
                        <TextField
                            inputLabel="Phone number" 
                            fieldId="owner-phone" 
                            inputType="text" 
                            hasError={validationErrors.phone} 
                            returnFieldValue={(value)=>{setTicketOwner({...ticketOwner, ...{phone: value}})}}
                        />
                    </div>  
                </div>

                <div className='text-right mt-4'>
                    <button onClick={()=>{addOwner()}} className='w-44 p-3 bg-blue-400 text-white text-sm rounded transition duration-200 hover:bg-mms-blue'>
                        Add this user
                    </button>
                </div>
            </div>

            {!paymentComputed && <div className='mt-8'>
                <FormButton buttonLabel="Compute payment" buttonAction={()=>{computePayment()}} processing={false} />
            </div>}

            {paymentComputed && eventPrice !== null && 
                <div className='p-5 bg-white border border-gray-200 rounded-md mt-8'>
                    <p className='text-black text-sm font-medium'>Ticket Price & Payment</p>
                    <p className='text-black text-sm font-light mb-8'>Fill in the details of the owner(s) of this ticket below</p>

                    <div className='my-4 w-full'>
                        <SelectField
                            selectOptions = {paymentChannels}
                            inputLabel = "Payment Channel"
                            titleField = "label"
                            displayImage = {false}
                            imageField = ""
                            fieldId = "event"
                            preSelected={''}
                            preSelectedLabel='value'
                            hasError = {validationErrors && validationErrors.channel}
                            returnFieldValue={(value)=>{setTransactionDetails({...transactionDetails, ...{channel: value}})}}
                        />
                        <label className='text-sm font-thin block w-full pt-3'>How was payment received for this ticket?</label>
                    </div>  

                    {/* {eventPrice}
                    {ticketPayload.ticketOwners?.length} */}
                    <div className='w-full mt-8'>
                        <CurrencyField 
                            inputType="number" 
                            fieldId="limit"
                            inputLabel="Ticket price" 
                            currencySymbol={'₦'}
                            hasError={false} 
                            preloadValue={(eventPrice * ticketPayload.ticketOwners?.length)/100}
                            returnFieldValue={(value)=>{setTransactionDetails({...transactionDetails, ...{amount: value}})}}
                            disabled={true}
                        /> 
                        <label className='text-sm font-thin block w-full pt-3'>this price is set automatically as the event cost times the number of tickets and cannot be changed</label>
                    </div>

                    <div className='mt-8'>
                        <FormButton buttonLabel="Create Ticket(s)" buttonAction={()=>{}} processing={ticketsState.creatingTicket} />
                    </div>
                </div>
            }

        </>
    )
}

export default NewTicketForm