import { combineReducers } from "redux";
import { errorReducer } from "./errorReducer";
// import { createCard } from "../../../actions/cardsActions";
import eventsReducer from "./eventsReducer";
import facilitiesReducer from "./facilitiesReducer";
import statsReducer from "./statsReducer";
import teamReducer from "./teamReducer";
import ticketsReducer from "./ticketsReducer";
import transactionsReducer from "./transactionsReducer";
import usersReducer from "./usersReducer";
import utilitiesReducer from "./utilitiesReducer";

const rootReducer = combineReducers({
    // Add reducers here
    events: eventsReducer,
    facilities: facilitiesReducer,
    errors: errorReducer,
    tickets: ticketsReducer,
    users: usersReducer,
    utilities: utilitiesReducer,
    transactions: transactionsReducer,
    stats: statsReducer,
    team: teamReducer
});

export default rootReducer;