import axios from "axios"
import { authHeader } from "../../utils/utils"
import { LOADING_STATS, LOAD_STATS, STATS_ERROR } from "../types"

export const fetchSystemStats = () => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: LOADING_STATS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/stats`, { headers })
        console.log(response.data)
        dispatch( {
            type: LOAD_STATS,
            payload: response.data.data.stats
        })
    }
    catch(error){
        dispatch( {
            type: STATS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}