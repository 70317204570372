import React, { useState } from 'react'
import TimeField from 'react-simple-timefield-for-react18-temp';
import TimeIcon from '../icons/TimeIcon';

const TimeInputField = ({inputLabel, fieldId, inputType, hasError, preloadValue,  returnFieldValue}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue || '')
    // const id = generateCode(12)

    // const [fieldId, setFieldId] = useState(id)

    const focusField = () => {
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div 
            className={`w-full cursor-text border rounded border p-4 relative z-0 ${isFocused || fieldValue !== '' ? 'border-black' : 'border-black'} ${hasError && 'border-red-600'}`} 
            onClick={()=>{focusField()}} 
            onBlur={()=>{setIsFocused(false)}}
        >
            <label 
            className={`cursor-text bg-white text-sm z-10 absolute top-3 left-4 px-4 py-1 font-gilroy font-medium transition duration-200  
            ${isFocused || fieldValue !== '' ? '-translate-y-8' : 'translate-y-0'}  
            ${hasError ? 'text-red-600' : 'text-gray-500'}`}>
                {inputLabel}
            </label>

            <TimeField
                value={fieldValue}                       // {String}   required, format '00:00' or '00:00:00'
                onChange={(event, value) => {setValue(value)}} // {Function} required
                input={<input id={fieldId} type='text' className='border-none outline-none w-24' />}   // {Element}  default: <input type="text" />
                colon=":"                          // {String}   default: ":"
                showSeconds={false}                        // {Boolean}  default: false
            />
            <TimeIcon className='absolute top-4 right-4 w-5 h-5 z-50' />
        </div>
    )
}

export default TimeInputField