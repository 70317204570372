import { CREATE_FACILITY, CREATE_SUBSCRIPTION_PLAN, CREATING_FACILITY, CREATING_SUBSCRIPTION_PLAN, FACILITIES_ERROR, FETCHING_ALL_FACILITIES, FETCHING_FACILITY_DETAILS, FETCHING_FACILITY_PLANS, FETCH_ALL_FACILITIES, FETCH_FACILITY_DETAILS, FETCH_FACILITY_PLANS, UPDATE_FACILITY, UPDATE_SUBSCRIPTION_PLAN, UPDATING_FACILITY, UPDATING_SUBSCRIPTION_PLAN } from "../types"
import axios from 'axios'
import { applySort, authHeader, parseFilters } from '../../utils/utils'

export const fetchAllFacilities = (reqPagination, filters, sort) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        let appliedFilters = null
        
        if (filters && filters!==null && filters.length > 0) {
            appliedFilters = parseFilters(filters)
        }

        const headers = authHeader()
        dispatch( {
            type: FETCHING_ALL_FACILITIES,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/facilities?perPage=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })
        dispatch( {
            type: FETCH_ALL_FACILITIES,
            payload: response.data.data.facilities
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchFacilitySubscriptionPlans = (reqPagination, id) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{

        const headers = authHeader()
        dispatch( {
            type: FETCHING_FACILITY_PLANS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription-plans/facility/${id}?perPage=${pagination.perPage}&page=${pagination.page}`, { headers })
        dispatch( {
            type: FETCH_FACILITY_PLANS,
            payload: response.data.data.subscriptionPlans
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchSingleFacility = (facilityId) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: FETCHING_FACILITY_DETAILS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/facilities/${facilityId}`, { headers })
        dispatch( {
            type: FETCH_FACILITY_DETAILS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const createNewFacility = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: CREATING_FACILITY,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/facilities`, payload, { headers })
        dispatch( {
            type: CREATE_FACILITY,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const updateFacilityDetails = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: UPDATING_FACILITY,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/facilities/${payload.facilityId}`, payload, { headers })
        dispatch( {
            type: UPDATE_FACILITY,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const createSubscriptionPlan = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: CREATING_SUBSCRIPTION_PLAN,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription-plans`, payload, { headers })
        dispatch( {
            type: CREATE_SUBSCRIPTION_PLAN,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const editSubscriptionPlan = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: UPDATING_SUBSCRIPTION_PLAN,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/subscription-plans/${payload.planId}`, payload, { headers })
        dispatch( {
            type: UPDATE_SUBSCRIPTION_PLAN,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: FACILITIES_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const clearCreatedFacility = () => async dispatch => {   
    dispatch( {
        type: CREATE_FACILITY,
        payload: null
    })
}

export const clearUpdatedPlan = () => async dispatch => {   
    dispatch( {
        type: UPDATE_SUBSCRIPTION_PLAN,
        payload: null
    })
}

export const clearCreatedPlan = () => async dispatch => {   
    dispatch( {
        type: CREATE_SUBSCRIPTION_PLAN,
        payload: null
    })
}

// export const clearApprovalErrors = () => async dispatch => {   
//     dispatch({
//         type: CLEAR_APPROVAL_ERRORS
//     }) 
// }