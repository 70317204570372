import { CREATE_TICKET, CREATING_TICKET, FETCHING_ALL_TICKETS, FETCHING_EVENT_TICKETS, FETCH_ALL_TICKETS, FETCH_EVENT_TICKETS, TICKETS_ERROR, UPDATE_TICKET, UPDATING_TICKET } from '../types'
import axios from 'axios'
import { applySort, authHeader, parseFilters } from '../../utils/utils'

export const fetchAllTickets = (reqPagination, filters, sort) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        let appliedFilters = null
        
        if (filters && filters!==null && filters.length > 0) {
            appliedFilters = parseFilters(filters)
        }

        const headers = authHeader()
        dispatch( {
            type: FETCHING_ALL_TICKETS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tickets?perPage=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })
        dispatch( {
            type: FETCH_ALL_TICKETS,
            payload: response.data.data.tickets
        })
    }
    catch(error){
        dispatch( {
            type: TICKETS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchTicketsForEvent = (reqPagination, eventId) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 10
        }
    }
    try{
        const headers = authHeader()
        dispatch( {
            type: FETCHING_EVENT_TICKETS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tickets/event/${eventId}?perPage=${pagination.perPage}&page=${pagination.page}`, { headers })
        // console.log(response.data.data)
        dispatch( {
            type: FETCH_EVENT_TICKETS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: TICKETS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}


export const createNewTicket = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: CREATING_TICKET,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/tickets`, payload, { headers })
        dispatch( {
            type: CREATE_TICKET,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: TICKETS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const updateTicket = (ticketCode, payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: UPDATING_TICKET,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/tickets/${ticketCode}`, payload, { headers })
        dispatch( {
            type: UPDATE_TICKET,
            payload: response.data
        })
    }
    catch(error){
        dispatch( {
            type: TICKETS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const clearCreatedTicket = () => async dispatch => {   
    dispatch( {
        type: CREATE_TICKET,
        payload: null
    })
}

export const clearUpdatedTicket = () => async dispatch => {   
    dispatch( {
        type: UPDATE_TICKET,
        payload: null
    })
}

// export const clearRejected = () => async dispatch => {   
//     dispatch( {
//         type: REJECT_TRANSACTION,
//         payload: null
//     })
// }

// export const clearApprovalErrors = () => async dispatch => {   
//     dispatch({
//         type: CLEAR_APPROVAL_ERRORS
//     }) 
// }