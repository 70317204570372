import axios from 'axios'
import { applySort, authHeader, parseFilters } from '../../utils/utils'
import { FETCHING_ALL_TRANSACTIONS, FETCH_ALL_TRANSACTIONS, TRANSACTIONS_ERROR } from '../types'

export const fetchAllTransactions = (reqPagination, filters, sort) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        let appliedFilters = null
        
        if (filters && filters!==null && filters.length > 0) {
            appliedFilters = parseFilters(filters)
        }

        const headers = authHeader()
        dispatch( {
            type: FETCHING_ALL_TRANSACTIONS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/all?perPage=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })
        dispatch( {
            type: FETCH_ALL_TRANSACTIONS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: TRANSACTIONS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}