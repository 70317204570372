import { FETCHING_ALL_USERS, FETCHING_USER_DETAILS, FETCH_ALL_USERS, FETCH_USER_DETAILS, USERS_ERROR } from "../types"

const initialState = {
    fetchingUsers: false,
    users: [],
    usersError: null,
    fetchingUserDetails: false,
    userDetails: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case FETCHING_ALL_USERS:
        return {
            ...state,
            fetchingUsers:action.payload
        }
        case FETCH_ALL_USERS:
        return {
            ...state,
            fetchingUsers:false,
            users: action.payload
        }
        case FETCHING_USER_DETAILS:
        return {
            ...state,
            fetchingUserDetails: action.payload
        }
        case FETCH_USER_DETAILS:
        return {
            ...state,
            fetchingUserDetails: false,
            userDetails: action.payload
        }
        case USERS_ERROR:
        return {
            ...state,
            fetchingUserDetails: false,
            fetchingUsers: false,
            usersError: action.payload
        }

        
        default: return state
    }

}