import React, {useState} from 'react'
import FormButton from '../components/elements/form/FormButton';
import TextField from "../components/elements/form/TextField";
import Logo from "../assets/img/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import ErrorMessage from '../components/elements/ErrorMessage';
const Login = () => {
  let navigate = useNavigate()    
  const [errorMessage, setErrorMessage] = useState('');
  const [authPayload, setAuthPayload] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [processing, setProcessing] = useState(false);

  const [loggedInUser, setLoggedInUser] = useState({});

  const validateForm = () => {
    let errors = {}
    if(!authPayload.email || authPayload.email === '') {
        errors.email = true
    }

    if(!authPayload.password || authPayload.password === '') {
        errors.password = true
    }

    setValidationErrors(errors)

    return errors
  }

  const logInUser = async () => {
      try {
          if (Object.values(validateForm()).includes(true)) {
              setErrorMessage('Please fill in all required field(s) highlighted below')
              return
          }
          // setotpSent(!otpSent)
          const headers = {
              'Content-Type': 'application/json'
          }
      
          setProcessing(true)
          
          const requestPayload = authPayload
          
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/sessions`, requestPayload, { headers }) 
          // setLoggedInUser({
          //   accessToken: response.data.data.accessToken,
          //   refreshToken: response.data.data.refreshToken

          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("refreshToken", response.data.data.refreshToken);
          // })


          setTimeout( async () => {
            await getUserProfile(response.data.data.accessToken)
          }, 200);
          
        } catch (error) {
          console.log(error)
          setErrorMessage(error.response.data.message)
          setProcessing(false)
        }
      }
      
      const getUserProfile = async (bearerToken) => {
        try {
          // setotpSent(!otpSent)
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`
          }
          
          setProcessing(true)
          
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, { headers }) 
          const userDetails = {...loggedInUser, ...{userInfo: response.data.data}}

          if(response.data.data.accountType === 'USER' ) {
            localStorage.removeItem("accessToken");
            setErrorMessage('Sorry, you do not have access to this platform')

            setProcessing(false)
          } else {
            localStorage.setItem("user", JSON.stringify(userDetails));
            setTimeout(() => {
              navigate('/admin')
            }, 300);
          }

      } catch (error) {
          console.log(error)
          setErrorMessage(error.response.data.message)
          setProcessing(false)
      }
  }

  return (
    <div className='w-full min-h-screen flex items-center justify-center bg-blue-100 bg-opacity-50'>
      <div className='w-1/3 px-12'>
        <img src={Logo} alt="" className='w-16 ml-auto mr-auto mb-8' />
        <div className='bg-white rounded-md p-8 lg:p-12 shadow-xl shadow-blue-600/10'>
          {errorMessage!=='' && 
            <ErrorMessage 
                toggleClose={()=>{setErrorMessage('')}} 
                messageTitle={`Sorry, an error occured`} 
                messageText={errorMessage} 
            />
          }
          <div className='w-10/12 ml-auto mr-auto text-center mt-5'>
            <h3 className='text-2xl mb-4'>Welcome back!</h3>
            <p className='text-gray-400'>Please provide your registered email address and password to sign in to your account</p>
          </div>
          <div className='my-8 w-full'>
            <TextField
              inputLabel="Email Address" 
              fieldId="email" 
              inputType="email" 
              hasError={validationErrors.email} 
              returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{email: value}})}}
            />
          </div>
          <div className='my-8 w-full'>
            <TextField
              inputLabel="Password" 
              fieldId="password" 
              inputType="password" 
              hasError={validationErrors.password} 
              returnFieldValue={(value)=>{setAuthPayload({...authPayload, ...{password: value}})}}
            />
          </div>
          <div className='w-full text-center'>
            <p className='text-gray-400 text-sm'>Forgot your password? <Link className='text-blue-500 font-medium' to='password-reset'>Click here</Link> to get a new one</p>
          </div>
          <div className='my-8 w-full'>
            <FormButton buttonLabel="Login to your account" buttonAction={()=>{logInUser()}} />
            {/* <Link to="/admin" className='block w-full p-4 bg-black text-white text-center text-md rounded-md transition duration-200 hover:bg-allawee-ink-navy'>Login to your account</Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login