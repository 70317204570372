import React, { useEffect, useState } from 'react'
import FacilityCard from '../../../components/partials/facilities/FacilityCard'
import UserLayout from '../../../components/Layout/UserLayout'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedFacility, fetchAllFacilities } from '../../../store/actions/facilitiesActions'
import SlideOutModal from '../../../components/Layout/SlideOutModal'
import NewFacilityForm from '../../../components/partials/facilities/NewFacilityForm'
import InlinePreloader from '../../../components/elements/InlinePreloader'
import SuccessMessage from '../../../components/elements/SuccessMessage'

const Facilities = () => {
  const [creatingFacility, setCreatingFacility] = useState(false);
  const facilitiesState = useSelector(state => state.facilities)
  const dispatch = useDispatch()
  const [successMessage, setSuccessMessage] = useState(null);
  const [facilityPayload, setFacilityPayload] = useState({});

  useEffect(() => {
    dispatch(fetchAllFacilities())

    if(facilitiesState.createdFacility !== null) {
      setSuccessMessage('New facility created successfully!')
      dispatch(clearCreatedFacility())
      setCreatingFacility(false)
      setTimeout(() => {
        setFacilityPayload(null)
      }, 200);
    }

    return () => {
        
    };
  }, [dispatch, facilitiesState.createdFacility]);

  
  return (
    <UserLayout pageTitle="Facilities">

      {successMessage!==null && 
        <SuccessMessage toggleClose={()=>{setSuccessMessage(null)}} messageTitle={'Success'} messageText={successMessage}/>
      }
      <section className='w-full min-h-screen p-12 relative'>
          
        <div className='w-full flex items-center justify-between'>
          <div>
            <h4 className='font-medium text-lg mb-3 text-gray-400'>FACILITIES</h4>
            <div className='w-2/3'>
                <p className='text-gray-500 my-3 text-sm'>
                    Below is a list of Facilities at Murtala Muhammed Square. you can create a new facility by clicking on the button to the right or click on a facility below to see more details or edit the facility.
                </p>
            </div>
          </div>

          <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm' onClick={()=>{setCreatingFacility(true)}}>
            <PlusIcon className="text-white w-6 h-6" /> Create new facility
          </button>

        </div>

        {facilitiesState.fetchingFacilities === true ?
          <div className='w-full py-12 flex items-center justify-center'>
            {/* {eventsState.fetchingEvents.toString()} */}
            <InlinePreloader />
          </div>

          :
        
          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-8 gap-8'>
            {facilitiesState.facilities.map((facility,facilityIndex)=>(<FacilityCard key={facilityIndex} facility={facility} />))}
          </div>
        }

      </section>
      {!facilitiesState.fetchingFacilities && facilitiesState.facilities.length > 0 && 
          <SlideOutModal isOpen={creatingFacility} closeFunction={()=>{setCreatingFacility(false)}} title={`Create a new facility`} subTitle={`Provide details of the new facility in the form below`} >
            <NewFacilityForm 
              facilityPayload={facilityPayload}
              setFacilityPayload={(data)=>{setFacilityPayload(data)}}
            />
          </SlideOutModal>
      }
    </UserLayout>
  )
}

export default Facilities