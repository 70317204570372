import React from 'react'
import InlinePreloader from '../InlinePreloader'

const FormButton = ({buttonLabel, buttonAction, processing}) => {
  return (
    <button 
      onClick={()=>{buttonAction()}} 
      disabled={processing}
      className='w-full p-4 bg-black text-white text-md rounded transition duration-200 hover:bg-allawee-ink-navy flex items-center justify-center'>
        {processing ? <InlinePreloader /> : buttonLabel}
      </button>
  )
}

export default FormButton