import React, { useState, Fragment, useEffect } from 'react'
import DataTable from '../../../components/elements/DataTable'
import SearchIcon from '../../../components/elements/icons/SearchIcon'
import ErrorMessage from '../../../components/elements/ErrorMessage';
import UserLayout from '../../../components/Layout/UserLayout'
import { tableHeadersFields } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import InlinePreloader from '../../../components/elements/InlinePreloader'
import { fetchAllUsers } from '../../../store/actions/usersActions';

const Users = () => {
    const [transactionDialogOpen, setTransactionDialogOpen] = useState(false)
    const [activeDialogTransaction, setActiveDialogTransaction] = useState(null)
    const [error, setError] = useState('')  
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 25
    });
    const usersState = useSelector(state => state.users)
    const dispatch = useDispatch()

    const columnWidths = {
        userCode: 'w-2/12',
        name: 'w-2/12',
        email: 'w-2/12',
        phone: 'w-2/12',
        confirmationStatus: 'w-2/12',
        signupDate: 'w-2/12',
    }

    useEffect (() => {
        const fetchTransactions = async () => {
            dispatch(fetchAllUsers(pagination))
        }

        fetchTransactions()

    }, [dispatch, pagination])

    const TransactionLink = ({reference, index}) => {
        return (
            <button className='text-gray-500 font-medium' onClick={() => {openTransaction(index)}}>{reference}</button>
        )
    }

    const EmailConfirmed = ({status}) => {
        return (
            <div>
                {status === true && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-green-300 bg-opacity-30 text-green-500 border border-green-500`}>Confirmed</p>}
                {status === false && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-gray-300 bg-opacity-30 text-gray-500 border border-gray-500`}>Pending</p>}
                {/* {status === 'FAILED' && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-red-100 bg-opacity-30 text-red-500  border border-red-500`}>{status}</p>} */}
            </div>
        )
    }
    
    // const TransactionAmount = ({amount}) => {
    //     return (<p className="font-medium text-base">N {amount.toLocaleString() || 0}</p>)
    // }

    const cleanupData = (dataSet) => {
        const data = []
    
        dataSet.forEach((item, itemIndex) => {
            data.push(
                {
                    userCode: <TransactionLink reference={item.userCode} index={itemIndex} />,
                    name: item.name,
                    email: item.email,
                    phone: item.phone,
                    confirmationStatus: <EmailConfirmed status={item.emailConfirmed} />,
                    signupDate: `${new Date(item.createdAt).toDateString()} - ${new Date(item.createdAt).toLocaleTimeString()}`
                },
            )
        })
    
        return data
    }

    const openTransaction = (transactionIndex) => {
        setTransactionDialogOpen(true)
        setActiveDialogTransaction(transactionIndex)
        console.log(transactionDialogOpen)
        console.log(activeDialogTransaction)
    }
    
    const tableOptions = {
        selectable: false
    }

    const columnDataStyles = {}

    // eslint-disable-next-line no-unused-vars
    // const [selectedUsersCount, setSelectedUsersCount] = useState(0)

    // const getSelectionCount = (count) => {
    //     return setSelectedUsersCount(count)
    // }

    // const [filtersActive, setFiltersActive] = useState(false)

    // const toggleFilters = () => {
    //     setFiltersActive(!filtersActive)
    // }

    // const bulkActions= [
    //     {
    //         label: "Deactivate"
    //     }
    // ]

    // const sources = [
    //     {
    //         name: 'Cash'
    //     }, 
    //     {
    //         name: 'Credit/Debit Card'
    //     }
    // ]

    // const channels = [
    //     {
    //         name: 'Flutterwave'
    //     }, 
    //     {
    //         name: 'Monnify'
    //     }, 
    //     {
    //         name: 'Nano'
    //     }
    // ]

    // const statuses = [
    //     {
    //         name: 'Pending'
    //     }, 
    //     {
    //         name: 'Successful'
    //     }, 
    //     {
    //         name: 'Failed'
    //     }
    // ]

    return (
        <Fragment>
            <UserLayout pageTitle="Platform Users">
                {error && error!=='' &&  
                    <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                }

                <div className="w-full p-12">
                    <div className='flex flex-row justify-between items-center py-3 mb-5'>
                        <div className="w-full">
                            <h4 className='font-medium text-lg mb-3 text-gray-400'>Users on the MMS platform</h4>
                        </div>

                        <div className='w-8/12 flex flex-row-reverse gap-x-4 items-center'>
                            {/* <button className={`flex flex-row gap-x-2 items-center bg-mms-red rounded text-white px-4 py-3 text-xs transition duration-200  ${filtersActive ? '' : ''}`} onClick={() => {toggleFilters()}}>
                                <SlidersIcon classes="h-5 w-5 text-white" />
                                Filters
                            </button> */}
                            <div className="relative rounded shadow-sm border border-gray-500 py-3 px-4 bg-white border-opacity-30 w-1/2">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm mt-1">
                                        <SearchIcon classes="h-6 w-6 text-gray-500 text-opacity-30" />
                                    </span>
                                </div>
                                <input type="text" className="bg-white block text-xs w-full focus:border-transparent focus:outline-none pl-7" placeholder="Search by transaction reference" />
                            </div>
                        </div>
                    </div>

                    {/* {filtersActive && 
                        <div className='w-full rounded-md bg-gray-100 p-24 grid grid-cols-5 gap-4'>
                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Transaction channel</label>
                                <ListboxSelect 
                                    listOptions={channels}
                                    buttonClasses="bg-transparent text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200" 
                                />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Minimum amount</label>
                                <input type="number" placeholder='Minimum' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Maximum amount</label>
                                <input type="number" placeholder='Maximum' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Transaction date (from)</label>
                                <input type="date" placeholder='From' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Transaction date (to)</label>
                                <input type="date" placeholder='to' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Transaction status</label>
                                <ListboxSelect 
                                    listOptions={statuses}
                                    buttonClasses="bg-transparent text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200" 
                                />
                            </div>

                            <div>
                                <label className="block my-2 text-xs text-gray-700 text-opacity-50">Payment sources</label>
                                <ListboxSelect 
                                    listOptions={sources}
                                    buttonClasses="bg-transparent text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200" 
                                />
                            </div>

                            <div className='flex items-center mt-7'>
                                <button className='bg-mms-red text-white rounded px-4 py-3 text-xs shadow-md hover:bg-opacity-50 transition duration-200'>Apply filters</button>
                            </div>
                        </div>
                    } */}
                    
                    <div className='px-24'>
                        {usersState?.fetchingUsers === true  
                            ? 
                                <div className='px-44 py-4 flex flex-row items-center justify-center gap-x-5 p-5 w-full text-xs text-center bg-black bg-opacity-20 rounded-lg mt-8'>
                                    <div className="w-6">
                                        <InlinePreloader />
                                    </div>
                                    <p className="">Loading transactions...</p>
                                </div>
                            : 
                                <>
                                    {usersState?.users?.users?.length > 0 ? <DataTable
                                        tableHeaders={tableHeadersFields(cleanupData(usersState?.users?.users)[0]).headers} 
                                        tableData={cleanupData(usersState?.users?.users)} 
                                        columnWidths={columnWidths}
                                        columnDataStyles={columnDataStyles}
                                        allFields={tableHeadersFields(cleanupData(usersState?.users?.users)[0]).fields}
                                        onSelectItems={()=>{}}
                                        tableOptions={tableOptions}
                                        pagination={{...pagination, ...{total: usersState?.users?.total}}}
                                        // changePage={updateCurrentPage}
                                        // updatePerPage={updatePerPage}
                                    />
                                    :

                                    <div className='w-6/12 border rounded-md p-5 text-center mx-auto border-gray-500'>
                                        <p className='text-sm bg-gray-600'>Sorry, no users on the platform currently.</p>
                                    </div>
                                    
                                    }
                                </>
                        }
                    </div>
                </div>

            </UserLayout>
              


        </Fragment>
    )

}

export default Users