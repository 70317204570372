// events
export const FETCHING_ALL_EVENTS = 'FETCHING_ALL_EVENTS';
export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const CREATING_EVENT = 'CREATING_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATING_EVENT = 'UPDATING_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const FETCHING_EVENT_DETAILS = 'FETCHING_EVENT_DETAILS';
export const FETCH_EVENT_DETAILS = 'FETCH_EVENT_DETAILS';
export const FETCHING_FACILITY_EVENTS = 'FETCHING_FACILITY_EVENTS';
export const FETCH_FACILITY_EVENTS = 'FETCH_FACILITY_EVENTS';

// facilities
export const FETCHING_ALL_FACILITIES = 'FETCHING_ALL_FACILITIES';
export const FETCH_ALL_FACILITIES = 'FETCH_ALL_FACILITIES';
export const FACILITIES_ERROR = 'FACILITIES_ERROR';
export const CREATING_FACILITY = 'CREATING_FACILITY';
export const CREATE_FACILITY = 'CREATE_FACILITY';
export const UPDATING_FACILITY = 'UPDATING_FACILITY';
export const UPDATE_FACILITY = 'UPDATE_FACILITY';
export const FETCHING_FACILITY_DETAILS = 'FETCHING_FACILITY_DETAILS';
export const FETCH_FACILITY_DETAILS = 'FETCH_FACILITY_DETAILS';
export const FETCHING_FACILITY_PLANS = 'FETCHING_FACILITY_PLANS';
export const FETCH_FACILITY_PLANS = 'FETCH_FACILITY_PLANS';
export const CREATING_SUBSCRIPTION_PLAN = 'CREATING_SUBSCRIPTION_PLAN';
export const CREATE_SUBSCRIPTION_PLAN = 'CREATE_SUBSCRIPTION_PLAN';
export const UPDATING_SUBSCRIPTION_PLAN = 'UPDATING_SUBSCRIPTION_PLAN';
export const UPDATE_SUBSCRIPTION_PLAN = 'UPDATE_SUBSCRIPTION_PLAN';

// Tickets
export const FETCHING_ALL_TICKETS = 'FETCHING_ALL_TICKETS'
export const FETCH_ALL_TICKETS = 'FETCH_ALL_TICKETS'
export const FETCHING_EVENT_TICKETS = 'FETCHING_EVENT_TICKETS'
export const FETCH_EVENT_TICKETS = 'FETCH_EVENT_TICKETS'
export const CREATING_TICKET = 'CREATING_TICKET'
export const CREATE_TICKET = 'CREATE_TICKET'
export const UPDATING_TICKET = 'UPDATING_TICKET'
export const UPDATE_TICKET = 'UPDATE_TICKET'
export const TICKETS_ERROR = 'TICKETS_ERROR'

// TRANSACTIONS 
export const FETCHING_ALL_TRANSACTIONS = 'FETCHING_ALL_TRANSACTIONS'
export const FETCH_ALL_TRANSACTIONS = 'FETCH_ALL_TRANSACTIONS'
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR'

// STATS 
export const LOADING_STATS = 'LOADING_STATS'
export const LOAD_STATS = 'LOAD_STATS'
export const STATS_ERROR = 'STATS_ERROR'

// Users
export const FETCHING_ALL_USERS = 'FETCHING_ALL_USERS'
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS'
export const FETCHING_USER_DETAILS = 'FETCHING_USER_DETAILS'
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS'
export const USERS_ERROR = 'USERS_ERROR'

// Team
export const FETCHING_INVITATIONS = 'FETCHING_INVITATIONS'
export const FETCH_INVITATIONS = 'FETCH_INVITATIONS'
export const FETCHING_INVITATION_DETAILS = 'FETCHING_INVITATION_DETAILS'
export const FETCH_INVITATION_DETAILS = 'FETCH_INVITATION_DETAILS'
export const FETCHING_TEAM = 'FETCHING_TEAM'
export const FETCH_TEAM = 'FETCH_TEAM'
export const REVOKING_INVITATION = 'REVOKING_INVITATION'
export const REVOKE_INVITATION = 'REVOKE_INVITATION'
export const TEAM_ERROR = 'TEAM_ERROR'

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// utilities
export const UPLOADING_FILE = 'UPLOADING_FILE'
export const UPLOADED_FILE = 'UPLOADED_FILE'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'

