import { LOADING_STATS, LOAD_STATS, STATS_ERROR } from "../types"

const initialState = {
    loadingStats: false,
    stats: null,
    statsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case LOADING_STATS:
        return {
            ...state,
            loadingStats:action.payload
        }
        case LOAD_STATS:
        return {
            ...state,
            loadingStats:false,
            stats: action.payload
        }
        case STATS_ERROR:
        return {
            ...state,
            loadingStats:false,
            statsError:action.payload,
        }
        
        default: return state
    }

}