import React from 'react'
import { Link } from 'react-router-dom'
import ArrowNarrowRight from '../../elements/icons/ArrowNarrowRight'
import TicketIcon from '../../elements/icons/TicketIcon'

const EventCard = ({eventDetails}) => {
  return (
    <div className='w-full bg-gray-100 relative' style={{height: '70vh'}}>

        {eventDetails?.cancelled === true && 
            <span className='font-outfit absolute top-4 right-4 ml-3 text-sm border rounded border-red-500 text-red-500 px-2 py-1'>Event Cancelled</span>
        }
        {(!eventDetails?.cancelled || eventDetails?.cancelled === false) && (!eventDetails?.published || eventDetails?.published === false) && 
            <span className='font-outfit absolute top-4 right-4 ml-3 text-sm border rounded border-gray-500 text-gray-500 px-2 py-1'>Draft Event</span>
        }

        <div className='absolute top-5 left-5 flex items-center gap-x-2'>
            <TicketIcon className="w-12 h-12 bg-mms-red bg-opacity-10 text-mms-red rounded-full p-3" />
            <p className='text-sm'>{eventDetails.ticketsSold} ticket(s) sold</p>
        </div>

        {eventDetails.eventType === 'VERSUS' && <div className='w-full flex items-center gap-x-4 px-8 mt-16 pt-5'>
            <div className='relative w-1/5 animate__animated animate__fadeIn'>
                {eventDetails.homeSide.logoUrl && eventDetails.homeSide.logoUrl !== '' ? 
                    <div className='rounded-md border border-gray-300 w-[65px] h-[65px]' style={{backgroundImage: `url(${ eventDetails.homeSide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    </div>
                    :
                    <div className='w-[60px] h-[60px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                }
            </div>
            <p className='text-sm font-light text-gray-500'>VS</p>
            <div className='relative w-1/5 animate__animated animate__fadeIn'>
                {eventDetails.awaySide.logoUrl && eventDetails.awaySide.logoUrl !== '' ? 
                    <div className='rounded-md border border-gray-300 w-[65px] h-[65px]' style={{backgroundImage: `url(${ eventDetails.awaySide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    </div>
                    :
                    <div className='w-[60px] h-[60px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                }
            </div>
        </div>}

        <div className={`px-8 mt-4 pt-3 ${eventDetails.eventType !== 'VERSUS' && 'pt-44'}`}>
            {eventDetails.eventType === 'VERSUS' && <>
                <p className='font-medium text-lg'>{eventDetails.homeSide.name} </p>
                <p className='text-gray-500 font-light'>Vs </p>
                <p className='font-medium text-lg'>{eventDetails.awaySide.name} </p>
            </> 
            }
            <p className='text-lg mt-5'>{eventDetails.name} <span></span> </p>
            <p className='text-sm font-light text-gray-500 my-4'>{eventDetails.description.length < 125 ? eventDetails.description : eventDetails.description.substr(0, 125) + '...'}</p>
            <p className='mt-3 text-sm'>Date: {new Date(eventDetails.eventDate).toDateString()}</p>
            {eventDetails.eventTime && <p className='mt-3 text-sm'>Time: {eventDetails.eventTime}</p>}
            <p className='mt-3 text-sm'>Venue: {eventDetails.venue.name}</p>
        </div>

        <Link to={`single/${eventDetails._id}`}>
            <button className='mt-8 text-black font-medium text-md px-8 py-4 absolute bottom-12 transition duration-200 animate__animated animate__fadeInUp flex items-center justify-center gap-x-2 hover:text-red-500'>
                See Details <ArrowNarrowRight className="w-8 mt-1 h-6" />
            </button>
        </Link>

    </div>
  )
}

export default EventCard