import React from 'react'
import FormButton from '../components/elements/form/FormButton';
import TextField from "../components/elements/form/TextField";
import Logo from "../assets/img/icon.png"
const AccountActivation = () => {
  return (
    <div className='w-full min-h-screen flex items-center justify-center bg-blue-100 bg-opacity-50'>
      <div className='w-1/3 px-12'>
        <img src={Logo} alt="" className='w-16 ml-auto mr-auto mb-8' />
        <div className='bg-white rounded-md p-8 lg:p-12 shadow-xl shadow-blue-600/10'>
          <div className='w-10/12 ml-auto mr-auto text-center'>
            <h3 className='text-2xl mb-4'>Welcome</h3>
            <p className='text-gray-400'>You found this page because you were invited to join the team at Murtala Muhammed Square. Please create a password for your account to activate your account and proceed</p>
          </div>

          <div className='my-8 w-full'>
            <TextField
              inputLabel="Password" 
              fieldId="password" 
              inputType="password" 
              hasError={false} 
              returnFieldValue={()=>{}}
            />
          </div>
          <div className='my-8 w-full'>
            <FormButton buttonLabel="Activate my account" buttonAction={()=>{}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountActivation