import React, { useState } from 'react'
import SettingsLayout from '../../components/Layout/SettingsLayout'
import { Switch } from '@headlessui/react'
import ModalLayout from '../../components/Layout/ModalLayout'
import DataTable from '../../components/elements/DataTable'
import PlusIcon from '../../components/elements/icons/PlusIcon'
import { tableHeadersFields } from '../../utils/utils'


const Team = () => {
    const invitees = [
        {
            name: "Michael J. Jordan",
            email: "mjordan@murtalasquare.com",
            phone: "+2348065739876",
            invitedOn: '012/12/2021'
        },
        {
            name: "Christopher S. Colombus",
            email: "ccolombus@murtalasquare.com",
            phone: "+2348065739876",
            invitedOn: '012/12/2021'
        }
    ]

    const team = [
        {
            name: "Michael J. Jordan",
            email: "mjordan@murtalasquare.com",
            phone: "+2348065739876",
            joinedOn: '012/12/2021'
        },
        {
            name: "Christopher S. Colombus",
            email: "ccolombus@murtalasquare.com",
            phone: "+2348065739876",
            joinedOn: '012/12/2021'
        },
        {
            name: "Michael J. Jordan",
            email: "mjordan@murtalasquare.com",
            phone: "+2348065739876",
            joinedOn: '012/12/2021'
        },
        {
            name: "Christopher S. Colombus",
            email: "ccolombus@murtalasquare.com",
            phone: "+2348065739876",
            joinedOn: '012/12/2021'
        }
    ]

    const teamColumnWidths = {
        name: 'w-3/12',
        email: 'w-3/12',
        phone: 'w-2/12',
        joinedOn: 'w-2/12',
        action: 'w-2/12'
    }

    const inviteeColumnWidths = {
        name: 'w-3/12',
        email: 'w-3/12',
        phone: 'w-2/12',
        invitedOn: 'w-4/12'
    }

    const tableOptions = {
        selectable: false,
        paginated: false
    }

    const columnDataStyles = {}

    const TeammateAction = () => {
        return (
            <button className='px-3 py-2 bg-transparent rounded text-xs text-accent hover:bg-accent hover:bg-opacity-30 transition duration-200'>Edit Permissions</button>
        )
    }

    const [userManagement, setUserManagement] = useState(false)
    const [billersManagement, setBillersManagement] = useState(false)
    const [ticketsManagement, setTicketsManagement] = useState(false)
    const [smartMeterControl, setSmartMeterControl] = useState(false)
    const [securityManagement, setSecurityManagement] = useState(false)

    const cleanupData = (dataFor, dataSet) => {
        const data = []

        dataSet.forEach((item, itemIndex) => {
            let dataObject = {
                name: item.name,
                email: item.email,
                phone: item.phone,
            }
            
            if(dataFor === 'invitees') {
                dataObject.invitedOn = item.invitedOn
            }

            if(dataFor === 'team') {
                dataObject.joinedOn = item.joinedOn
                dataObject.action = <TeammateAction />
            }

            data.push(dataObject)
        })

        return data
    }

    const [invitation, setInvitation] = useState(false)

    const sendInvitation = () => {
        console.log('nvitation sent...')
    }

    return (
        <>
        <SettingsLayout pageTitle="Team Members">
            {userDetails => (
                <>
                    <h3 className="text-secondary text-md font-medium">MMS Team</h3>
                    <p className='text-xs mt-4 mb-6'>Below are the murtala Muhammed Square team members, click on a member to view details and edit permissions or click on new teammate to invite a user to the team</p>

                    <div className='py-4 border-t border-secondary'>
                        <div>
                            <div className='w-full flex items-center justify-between'>
                                <h3 className="text-sm font-medium mb-4">Invitees</h3>
                                <button className='flex flex-row gap-x-2 items-center bg-mms-red text-white rounded px-4 py-2 text-xs shadow-md transition duration-200 hover:bg-opacity-50' onClick={()=>{setInvitation(true)}}>
                                    <PlusIcon className="h-5 w-5 text-accent text-opacity-60" />
                                    Invite new teammate
                                </button>
                            </div>

                            <DataTable
                                tableHeaders={tableHeadersFields(cleanupData('invitees', invitees)[0]).headers} 
                                tableData={cleanupData('invitees', invitees)} 
                                columnWidths={inviteeColumnWidths}
                                columnDataStyles={columnDataStyles}
                                allFields={tableHeadersFields(cleanupData('invitees', invitees)[0]).fields}
                                // onSelectItems={getSelectionCount}
                                tableOptions={tableOptions}
                            />
                        </div>

                        <div className='mt-8 pt-4 border-t border-secondary'>
                            <h3 className="text-sm font-medium mb-4">MMS Team</h3>

                            <DataTable
                                tableHeaders={tableHeadersFields(cleanupData('team', team)[0]).headers} 
                                tableData={cleanupData('team', team)} 
                                columnWidths={teamColumnWidths}
                                columnDataStyles={columnDataStyles}
                                allFields={tableHeadersFields(cleanupData('team', team)[0]).fields}
                                // onSelectItems={getSelectionCount}
                                tableOptions={tableOptions}
                            />
                        </div>
                    </div>
                </>
            )}


        </SettingsLayout>
        
        <ModalLayout 
            isOpen={invitation} 
            closeModal={()=>{setInvitation(false)}} 
            actionFunction={()=>{sendInvitation()}} 
            actionFunctionLabel='Send Invitation' 
            dialogTitle='Invite a Teammate'
            maxWidthClass='max-w-4xl'
        >
            <div className='w-full flex flex-row gap-x-4'>
                <div className='w-1/2'>
                    <div>
                        <label className="mt-6 mb-3 text-xs text-primary block">Name</label>
                        <input type="text" placeholder="Full name of invitee" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 bg-white focus:border-opacity-90 text-xs focus:outline-none transition duration-200" />
                    </div>
                    <div>
                        <label className="mt-6 mb-3 text-xs text-primary block">Phone number</label>
                        <input type="text" placeholder="Active phone number of invitee" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 bg-white focus:border-opacity-90 text-xs focus:outline-none transition duration-200" />
                    </div>
                    <div>
                        <label className="mt-6 mb-3 text-xs text-primary block">Email address <span className="text-red-400 font-medium">(must be a emplays.com email)</span></label>
                        <input type="email" placeholder="email of invitee (invitee@murtalasquare.com)" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 bg-white focus:border-opacity-90 text-xs focus:outline-none transition duration-200" />
                        <label className="mt-2 mb-3 text-xs text-secondary text-opacity-50 block">An email will be sent to this address for confirmation and account set-up</label>
                    </div>
                    <div className='w-full'>
                        <label className="mt-6 mb-3 text-xs text-primary block">Account Type</label>
                        <select className="text-primary w-full px-3 py-3 rounded border border-primary border-opacity-30 bg-white focus:border-opacity-90 text-xs focus:outline-none transition duration-200">
                            <option>Administrator</option>
                            <option>Support</option>
                            <option>Super Administrator</option>
                        </select>
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className="mt-5">
                        <p className="font-medium text-sm mb-6">Account Permissions</p>

                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">User Management</label>
                            <Switch
                                checked={userManagement}
                                onChange={setUserManagement}
                                className={`${
                                    userManagement ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Enable on contact page</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        userManagement ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>
                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">Events Management</label>
                            <Switch
                                checked={smartMeterControl}
                                onChange={setSmartMeterControl}
                                className={`${
                                    smartMeterControl ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Enable on contact page</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        smartMeterControl ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>
                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">Facilities Management</label>
                            <Switch
                                checked={smartMeterControl}
                                onChange={setSmartMeterControl}
                                className={`${
                                    smartMeterControl ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Enable on contact page</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        smartMeterControl ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>
                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">Approvals</label>
                            <Switch
                                checked={billersManagement}
                                onChange={setBillersManagement}
                                className={`${
                                    billersManagement ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Enable on contact page</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        billersManagement ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>

                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">Tickets Management</label>
                            <Switch
                                checked={ticketsManagement}
                                onChange={setTicketsManagement}
                                className={`${
                                    ticketsManagement ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Tickets management</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        ticketsManagement ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>
                        <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                            <label className="text-xs text-gray-500 font-sofia-pro">Security Management</label>
                            <Switch
                                checked={securityManagement}
                                onChange={setSecurityManagement}
                                className={`${
                                    securityManagement ? 'bg-blue-100' : 'bg-gray-400'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                <span className="sr-only">Security management</span>
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        securityManagement ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                                    } inline-block w-3 h-3 transform rounded-full`}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex my-4 flex-row-reverse'>
                <button className="px-4 rounded py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm">
                    Send Invitation
                </button>
            </div>

        </ModalLayout>
        </>
    )
}

export default Team
