import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTicket } from '../../../store/actions/ticketsActions';
import { fetchUserDetails } from '../../../store/actions/usersActions';
import { timeStamp } from '../../../utils/utils';
import CheckIconCircled from '../../elements/icons/CheckIconCircled'
import InlinePreloader from '../../elements/InlinePreloader';

const TicketDetails = ({ticket}) => {
    const dispatch = useDispatch()
    const usersState = useSelector(state => state.users)
    const ticketsState = useSelector(state => state.tickets)

    useEffect(() => {
        dispatch(fetchUserDetails(ticket?.ticketOwner))
        return () => {

        };
    }, [dispatch, ticket?.ticketOwner]);

    const admitGuest = () =>  {
        const payload = {
            ticketStatus: "ADMITTED"
        }
        dispatch(updateTicket(ticket?.ticketCode, payload))
    }
  return (
    <>
    {usersState?.fetchingUserDetails === true ? 
        <div className='w-full flex items-center justify-center h-12'>
            <InlinePreloader />
        </div>
        
        :

        <div>
            <div className='text-center mt-12'>
                <div className='relative flex items-center justify-center w-full mx-auto animate__animated animate__fadeIn'>
                    <img
                        src={ticket?.qrCode}
                        layout="responsive" 
                        quality={100} 
                        alt='' 
                    />
                </div>
                <p className='text-gray-400 mt-3'>Ticket Code: <span className='font-medium text-lg'>{ticket?.ticketCode}</span></p>
            </div>

            <div className='w-full px-12'>
                {ticket.event?.eventType === 'VERSUS' && <div className='w-8/12 mx-auto flex items-center justify-between gap-x-4 px-3 py-3'>
                    <div className='relative w-16 animate__animated animate__fadeIn'>
                        <img
                            src={ticket?.event?.homeSide?.logoUrl}
                            layout="responsive" 
                            quality={100} 
                            alt='' 
                        />
                    </div>
                    <p className='text-sm font-light text-gray-500'>VS</p>
                    <div className='relative w-16 animate__animated animate__fadeIn'>
                        <img
                            src={ticket?.event?.awaySide?.logoUrl}
                            layout="responsive" 
                            quality={100} 
                            alt='' 
                        />
                    </div>
                </div>}

                <div className='px-3 mt-2 pt-3'>
                {ticket.event?.eventType === 'VERSUS' && 
                    <p className='font-medium text-xl'>{ticket.event?.homeSide?.name} <span className='text-gray-500 font-light'>Vs </span> {ticket.event?.awaySide?.name}</p> 
                    }
                    <p className='font-thin text-lg'>{ticket?.event?.name} </p>

                    <div className='w-full block gap-x-3'>
                        <p className='mt-3 text-sm'>Date: {new Date(ticket?.event?.eventDate).toDateString()}</p>
                        <p className='mt-3 text-sm'>Time: {ticket?.event?.eventTime}</p>
                        <p className='mt-3 text-sm'>Venue: {ticket?.event?.venue?.name}</p>
                    </div>
                </div>

                <div className='px-3 mt-2 pt-3'>
                <p className='font-medium text-md'>Guest Details</p> 

                    <div className='w-full block gap-x-3'>
                        <p className='mt-3 text-sm'>Name: {usersState.userDetails?.name}</p>
                        <p className='mt-3 text-sm'>Email: {usersState.userDetails?.email}</p>
                        <p className='mt-3 text-sm'>Phone: {usersState.userDetails?.phone}</p>
                    </div>
                </div>

            </div>
            {ticket?.ticketStatus === 'PENDING' && <div className='w-full px-12 flex mt-12'>
                <button onClick={()=>{admitGuest()}} disabled={ticketsState.updatingTicket === true} className="w-full px-4 rounded py-4 bg-mms-red text-white flex items-center justify-center gap-x-2 text-sm">
                {ticketsState.updatingTicket === true ? 
                    <InlinePreloader /> 
                    :
                    <>
                        Admit Guest <CheckIconCircled className='w-6 h-6' />
                    </>
                }

                </button>
            </div>}
            {ticket?.ticketStatus === 'ADMITTED' &&
                <div className='w-full px-12 flex mt-12'>
                    <div className="w-full px-4 rounded py-4 bg-gray-200 mt-5 text-white flex items-center justify-center gap-x-2 text-sm flex items-center justify-center text-gray-600">
                        <p className='text-sm font-thin'>Guest admitted at {timeStamp(ticket?.updatedAt)?.date} - {timeStamp(ticket?.updatedAt)?.time} </p>
                    </div>
                </div>
            }
        </div>
    }
    </>
  )
}

export default TicketDetails