import { CREATE_EVENT, CREATING_EVENT, EVENTS_ERROR, FETCHING_ALL_EVENTS, FETCHING_EVENT_DETAILS, FETCHING_FACILITY_EVENTS, FETCH_ALL_EVENTS, FETCH_EVENT_DETAILS, FETCH_FACILITY_EVENTS, UPDATE_EVENT, UPDATING_EVENT } from "../types"
import axios from 'axios'
import { applySort, authHeader, parseFilters } from '../../utils/utils'

export const fetchEvents = (reqPagination, filters, sort) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        let appliedFilters = null
        
        if (filters && filters!==null && filters.length > 0) {
            appliedFilters = parseFilters(filters)
        }

        const headers = authHeader()
        dispatch( {
            type: FETCHING_ALL_EVENTS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events?perPage=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })
        console.log(response)
        dispatch( {
            type: FETCH_ALL_EVENTS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: EVENTS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchEventsForFacility = (reqPagination, facilityId) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination || reqPagination === null) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        const headers = authHeader()
        dispatch( {
            type: FETCHING_FACILITY_EVENTS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/facility/${facilityId}?limit=${pagination.perPage}&count=${pagination.page}`, { headers })
        console.log(response)
        dispatch( {
            type: FETCH_FACILITY_EVENTS,
            payload: response.data.data.events
        })
    }
    catch(error){
        dispatch( {
            type: EVENTS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchSingleEvent = (eventId) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: FETCHING_EVENT_DETAILS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`, { headers })
        dispatch( {
            type: FETCH_EVENT_DETAILS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: EVENTS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}


export const createNewEvent = (payload) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: CREATING_EVENT,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/events`, payload, { headers })
        dispatch( {
            type: CREATE_EVENT,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: EVENTS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const updateEventDetails = (payload) => async dispatch => {    
    console.log('3', payload)
    try{
        const headers = authHeader()
        dispatch( {
            type: UPDATING_EVENT,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/events/${payload.eventId}`, payload, { headers })
        dispatch( {
            type: UPDATE_EVENT,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: EVENTS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const clearUpdatedEvent = () => async dispatch => {   
    dispatch( {
        type: UPDATE_EVENT,
        payload: null
    })
}

export const clearCreatedEvent = () => async dispatch => {   
    dispatch( {
        type: CREATE_EVENT,
        payload: null
    })
}

// export const clearApprovalErrors = () => async dispatch => {   
//     dispatch({
//         type: CLEAR_APPROVAL_ERRORS
//     }) 
// }