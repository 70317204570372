import React, { useState, useCallback } from 'react'
import ChevronIcon from './icons/ChevronIcon copy'


const MediaSlider = ({slides}) => {
    const [ activeSlide, setActiveSlide ] = useState(0)
    const [ slideActive, setSlideActive ] = useState(true)

    const nextSlide = useCallback(() => {
        setSlideActive(false)
        if (activeSlide === slides?.length - 1) {
            setActiveSlide(0)
        } else {
            setActiveSlide(activeSlide + 1)
        }
        setTimeout(() => {
            setSlideActive(true)
        }, 50);
    }, [activeSlide, slides])
    
    const previousSlide = () => {
        setSlideActive(false)
        if (activeSlide === 0) {
            setActiveSlide(slides.length - 1)
        } else {
            setActiveSlide(activeSlide - 1)
        }
        setTimeout(() => {
            setSlideActive(true)
        }, 50);
    }
    
    // useEffect(() => {
    //     setInterval(() => {
    //         nextSlide()
    //     }, 20000);
    // }, [nextSlide])

    return (
        <section id="slider" className='w-full h-screen relative'  style={{height: '70vh'}}>
            {/* {slideActive &&  */}
                <div className='w-full z-10'  style={{height: '70vh'}}>
                    {slides&& <div className='relative w-full animate__animated fadeIn' style={{height: '70vh', backgroundImage: `url(${ slides[activeSlide]?.itemUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />}
                </div>
            {/* } */}

            <div className={`w-full bg-black bg-opacity-40 z-30 absolute left-0 top-0 transition duration-200`} style={{height: '70vh'}}>

                <div className='hidden lg:flex justify-between items-center px-32 w-full absolute left-0 top-0'  style={{height: '70vh'}}>
                    <button className='text-green-300 bg-green-400 bg-opacity-10 rounded-full w-12 h-12 flex items-center justify-center transition duration-200 hover:bg-opacity-30' onClick={()=>{previousSlide()}}>
                        <ChevronIcon className="w-5 h-5 rotate-90" />
                    </button>
                    <button className='text-green-300 bg-green-400 bg-opacity-10 rounded-full w-12 h-12 flex items-center justify-center transition duration-200 hover:bg-opacity-30' onClick={()=>{nextSlide()}}>
                        <ChevronIcon className="w-5 h-5 -rotate-90" />
                    </button>
                </div>

                {/* {slideActive && 
                    <div className='w-11/12 lg:w-6/12 absolute left-4 lg:left-52 bottom-16 bg-black bg-opacity-50 p-12' data-aos="fade-up">
                        <h3 className='text-2xl text-gray-300 font-outfit'>
                            {slides[activeSlide].title}
                        </h3>
                    </div>
                } */}
            </div>
        </section>
    )
}   

export default MediaSlider