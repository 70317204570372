import { Switch } from '@headlessui/react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewEvent } from '../../../store/actions/eventsActions';
import { ERROR } from '../../../store/types';
import { eventTypes } from '../../../utils/utils';
import AutocompleteSelect from '../../elements/form/AutocompleteSelect';
import CurrencyField from '../../elements/form/CurrencyField';
import DateField from '../../elements/form/DateField';
import FileUpload from '../../elements/form/FileUpload';
import FormButton from '../../elements/form/FormButton';
import SelectField from '../../elements/form/SelectField';
import TextareaField from '../../elements/form/TextareaField';
import TextField from '../../elements/form/TextField'
import TimeInputField from '../../elements/form/TimeInputField';

const EditEventForm = ({facilities, eventPayload, setEventPayload, doUpdate}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()
    const eventsState = useSelector(state => state.events)

    const [sellingTickets, setSellingTickets] = useState(eventPayload.sellingTickets);
    const [file, setFile] = useState(null);
    const [publishEvent, setPublishEvent] = useState(eventPayload.published);

    const validateForm = () => {
        let errors = {}
        // if(!eventPayload.email || eventPayload.email === '') {
        //     errors.email = true
        // }
        // if(!file || file === '') {
        //     errors.file = true
        // }
    
        setValidationErrors(errors)
        return errors
    }

    const updateEvent = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please fill in all required field(s) highlighted below'
                }}}
            })
            return
        }

        doUpdate(eventPayload)   
    }

    return (
        <>
            <div className='my-8 w-full'>
                <TextField
                    inputLabel="Event name" 
                    fieldId="event-name" 
                    inputType="text" 
                    preloadValue={eventPayload.name || ''}
                    hasError={validationErrors.name} 
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{name: value}})}}
                />
            </div>  

            <div className='my-8 w-full flex gap-x-4 justify-between'>
                <div className='w-8/12'>
                    <DateField
                        inputLabel="Event Date" 
                        fieldId="event-date" 
                        hasError={validationErrors.date} 
                        preloadValue={new Date(eventPayload.eventDate).toISOString().substring(0, 10) || ''}
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventDate: value}})}}
                    />
                </div>
                <div className='w-4/12'>
                    <TimeInputField
                        inputLabel="Event Time" 
                        fieldId="event-time" 
                        preloadValue={eventPayload.eventTime || ''}
                        hasError={validationErrors.time} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventTime: value}})}}
                    />
                </div>
            </div>  

            <div className='my-8 w-full'>
                <TextareaField
                    inputLabel="About the event" 
                    fieldId="event-description" 
                    inputType="text" 
                    preloadValue={eventPayload.description || ''}
                    hasError={validationErrors.description} 
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{description: value}})}}
                />
                <label className='text-sm font-thin block w-full pt-3'>Some details about the event - let people know what to expect</label>

            </div>  

            <div className='my-8 w-full'>
                <SelectField
                    selectOptions = {eventTypes}
                    inputLabel = "Type of event"
                    titleField = "label"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event-type"
                    preSelected={eventPayload.eventType}
                    preSelectedLabel='value'
                    hasError = {validationErrors && validationErrors.eventType}
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventType: value.value}})}}
                />
            </div>  

            {eventPayload?.eventType === 'VERSUS' && <div className='p-5 bg-white border border-gray-200 rounded-md'>
                <p className='text-black text-sm font-thin'><span className='font-medium'>Versus</span> events require a home and an away team, please provide their details here</p>

                <p className='text-black mt-3 mb-5'>Home Team</p>

                <div className='my-8 w-full'>
                    <TextField
                        inputLabel="Team name" 
                        fieldId="home-team-name" 
                        inputType="text" 
                        hasError={validationErrors.homeName} 
                        preloadValue={eventPayload?.homeSide?.name || ''}
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{name: value}})}}
                    />
                </div>  

                <div className='my-8 w-full'>
                    <FileUpload
                        hasError={false}
                        fieldLabel="Change home team logo"
                        returnFileDetails={(details)=>{setFile(details)}}
                        acceptedFormats={['png']}
                    />
                    <label className='text-sm font-thin block w-full -mt-6'>Logos should be a png image with transparent background and 1:1 aspect ration</label>
                </div>

                <p className='text-black mt-3 mb-5'>Away Team</p>

                <div className='my-8 w-full'>
                    <TextField
                        inputLabel="Team name" 
                        fieldId="away-team-name" 
                        inputType="text" 
                        preloadValue={eventPayload?.awaySide?.name || ''}
                        hasError={validationErrors.homeName} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{name: value}})}}
                    />
                </div>  

                <div className='my-8 w-full'>
                    <FileUpload
                        hasError={false}
                        fieldLabel="Change away team logo"
                        returnFileDetails={(details)=>{setFile(details)}}
                        acceptedFormats={['png']}
                    />
                    <label className='text-sm font-thin block w-full -mt-6'>Logos should be a png image with transparent background and 1:1 aspect ration</label>
                </div>

            </div>}
            <div className='my-8 w-full'>
                <AutocompleteSelect
                    selectOptions = {facilities}
                    inputLabel = "Event venue"
                    titleField = "name"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event-venue"
                    preSelected={eventPayload?.venue?._id}
                    preSelectedLabel='_id'
                    hasError = {validationErrors && validationErrors.eventVenue}
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventVenue: value}})}}
                />
                <label className='text-sm font-thin block w-full pt-3'>Where will this event take place? select from a list of Murtalasquare facilities above</label>
            </div>  

            <div className='my-8 w-full flex gap-x-4 justify-between'>
                <div className='w-8/12'>
                    <CurrencyField 
                        inputType="number" 
                        fieldId="limit"
                        inputLabel="Ticket price" 
                        currencySymbol={'₦'}
                        hasError={validationErrors && validationErrors.ticketPrice} 
                        preloadValue={eventPayload?.ticketPrice/100 || ''}
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{ticketPrice: value * 100}})}}
                        maximumAmount={50000}
                    /> 
                    <label className='text-sm font-thin block w-full pt-3'>Ignore this if the event is free</label>

                </div>
                <div className='w-4/12 text-right'>
                    <label className="text-sm text-gray-500 font-sofia-pro block w-full mb-2">Currently Selling?</label>
                    <Switch
                        checked={sellingTickets}
                        onChange={setSellingTickets}
                        className={`${
                            sellingTickets ? 'bg-blue-200' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sellingTickets ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
            </div> 

            <div className='my-8 w-full'>
                <FileUpload
                    hasError={false}
                    fieldLabel="Change event cover image"
                    returnFileDetails={(details)=>{setFile(details)}}
                    acceptedFormats={['jpg', 'jpeg', 'png']}
                />
            </div>

            <div className='my-8 w-full'>
                <div className='full text-right mb-6'>
                    <label className="text-sm text-gray-500 font-sofia-pro w-full mb-2 mr-4">Toggle this switch to publish or unpublish this event</label>
                    <Switch
                        checked={publishEvent}
                        onChange={setPublishEvent}
                        className={`${
                            publishEvent ? 'bg-blue-200' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                publishEvent ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
                <FormButton buttonLabel="Update Event" buttonAction={()=>{updateEvent()}} processing={eventsState.updatingEvent} />
            </div>
        </>
    )
}

export default EditEventForm