import React from 'react'
import UserHeader from '../partials/UserHeader'
import UserSidebar from '../partials/UserSidebar'

const UserLayout = ({children, pageTitle}) => {
  return (
        <>
            <div className='flex flex-row'>
                    <div className='w-16 fixed h-screen border-r border-gray-100 z-40 bg-white'>
                        <UserSidebar />
                    </div>
                    <div className='w-full pl-16'>
                        <div className='fixed h-16 flex items-center border-b px-12 py-2 border-gray-100 w-full z-40 bg-white'>
                            <UserHeader pageTitle={pageTitle}/>
                        </div>
                        <div className='pt-16'>
                            {children}
                        </div>
                    </div>
            </div>
        </>
    )
}

export default UserLayout