import { Switch } from '@headlessui/react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewFacility } from '../../../store/actions/facilitiesActions';
import { CREATING_FACILITY, ERROR } from '../../../store/types';
import { authHeader, facilityTypes } from '../../../utils/utils';
import FileUpload from '../../elements/form/FileUpload';
import FormButton from '../../elements/form/FormButton';
import NumberField from '../../elements/form/NumberField';
import SelectField from '../../elements/form/SelectField';
import TextareaField from '../../elements/form/TextareaField';
import TextField from '../../elements/form/TextField';
// import PlusIcon from '../../elements/icons/PlusIcon';

const NewFacilityForm = ({facilityPayload, setFacilityPayload}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()
    const facilitiesState = useSelector(state => state.facilities)


    const [file, setFile] = useState(null);
    const [publishEvent, setPublishEvent] = useState(false);

    const validateForm = () => {
        let errors = {}
        if(!facilityPayload.name || facilityPayload.name === '') {
            errors.name = true
        }
        if(!facilityPayload.description || facilityPayload.description === '') {
            errors.description = true
        }
        if(facilityPayload.capacity === '') {
            errors.capacity = true
        }
        if(!facilityPayload.type || facilityPayload.type === '') {
            errors.type = true
        }
    
        setValidationErrors(errors)
        return errors
    }

    const createFacility = (media) => {
        const payload = {
            name: facilityPayload.name,
            description: facilityPayload.description,
            capacity: facilityPayload.capacity,
            geolocation: {
                latitude: facilityPayload.longitude,
                longitude: facilityPayload.latitude
            },
            type:facilityPayload.type,
            published: publishEvent
        }

        if(media && media.length > 0) {
            payload.media = media
        }

        dispatch(createNewFacility(payload))   
    }

    const triggerCreateFacility = () => {

        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please fill in all required field(s) highlighted below'
                }}}
            })
            return
        }
        
        if(file && file !== '') {
            uploadFile({file})
        } else {
            createFacility()
        }
    }

    const uploadFile = async () => {
        const headers = new Headers();
        headers.append("Authorization",  authHeader().Authorization);
        try {

            var data = new FormData();
            data.append('file', file.file)
            dispatch({
                type: CREATING_FACILITY,
                payload: true,
            });
            const upload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: data,
            });
            const response = await upload.json();
            console.log(response)
            if(response.status === false) {
                dispatch({
                    type: ERROR,
                    // payload: error,
                    error: {response: {data: {
                            message: response.message
                        }}}
                });
                dispatch({
                    type: CREATING_FACILITY,
                    payload: false,
                });
                return
            }


            setTimeout(() => {
                createFacility([ 
                    {
                      itemType: "IMAGE",
                      itemUrl: response.data.file
                    }
                ])
            }, 200);

        } catch (error) {
            console.log("err",error);
            dispatch({
                type: ERROR,
                // payload: error,
                error,
            });
            dispatch({
                type: CREATING_FACILITY,
                payload: false,
            });
        }
    }

    return (
        <>
            <div className='my-8 w-full'>
                <TextField
                    inputLabel="Name of facility" 
                    fieldId="facility-name" 
                    inputType="text" 
                    hasError={validationErrors.name} 
                    returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{name: value}})}}
                />
            </div>  

            <div className='my-8 w-full'>
                <TextareaField
                    inputLabel="About the facility" 
                    fieldId="facility-description" 
                    inputType="text" 
                    preloadValue=""
                    hasError={validationErrors.description} 
                    returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{description: value}})}}
                />
                <label className='text-sm block w-full pt-3'>Some details about the facility - let people know what to expect</label>

            </div>  

            <div className='my-8 w-full'>
                <SelectField
                    selectOptions = {facilityTypes}
                    inputLabel = "Type of facility"
                    titleField = "label"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event-type"
                    preSelected={''}
                    preSelectedLabel='value'
                    hasError = {validationErrors && validationErrors.type}
                    returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{type: value.value}})}}
                />
            </div>  

            <div className='w-full my-8'>
                <NumberField 
                    fieldId="facility-capacity"
                    inputLabel="Capacity" 
                    hasError={validationErrors && validationErrors.capacity} 
                    preloadValue={''}
                    returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{capacity: value}})}}
                /> 
                <label className='text-sm block w-full pt-3'>Use 0 as the capacity if the facility has unlimited capacity</label>

            </div>

            <p className='block w-full pt-3'>Geolocation of facility</p>
            <p className='block w-full text-sm pt-1'>Provide GPS coordinates of the facility below (if available) to guide users via map </p>

            <div className='mb-8 mt-3 w-full flex gap-x-4 justify-between'>
                <div className='w-full'>
                    <NumberField 
                        fieldId="facility-long"
                        inputLabel="Latitude" 
                        hasError={false} 
                        preloadValue={''}
                        allowDecimal={true}
                        decimalScale={6}
                        returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{latitude: value.toString()}})}}
                    /> 

                </div>
                <div className='w-full'>
                    <NumberField 
                        fieldId="facility-lat"
                        inputLabel="Longitude" 
                        hasError={false} 
                        preloadValue={''}
                        allowDecimal={true}
                        decimalScale={6}
                        returnFieldValue={(value)=>{setFacilityPayload({...facilityPayload, ...{longitude: value.toString()}})}}
                    /> 

                </div>
            </div> 

            <div className='my-8 w-full flex gap-x-4 justify-between'>
                <div className='w-full'>
                    <FileUpload
                        hasError={false}
                        fieldLabel="Event cover image"
                        returnFileDetails={(details)=>{setFile(details)}}
                        acceptedFormats={['jpg', 'jpeg', 'png']}
                    />
                </div>
                {/* <div className='w-[70px] h-[50px] flex items-center justify-center'>
                    <button className='w-full bg-mms-red h-full text-white flex items-center justify-center rounded'><PlusIcon className='w-6 h-6' /></button>
                </div> */}
            </div>


            <div className='my-8 w-full'>
                <div className='full text-right mb-6'>
                    <label className="text-sm text-gray-500 font-sofia-pro w-full mb-2 mr-4">Toggle this switch to publish the facility right away</label>
                    <Switch
                        checked={publishEvent}
                        onChange={setPublishEvent}
                        className={`${
                            publishEvent ? 'bg-blue-200' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                publishEvent ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
                <FormButton buttonLabel="Create Facility" buttonAction={()=>{triggerCreateFacility()}} processing={facilitiesState.creatingFacility} />
            </div>
        </>
    )
}

export default NewFacilityForm