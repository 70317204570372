import React from 'react'
import ChevronIcon from './icons/ChevronIcon copy'

const Pagination = () => {
  return (
    <div className='flex justify-between items-center w-full my-6'>
        <button className='text-green-500 bg-green-400 bg-opacity-20 rounded-full w-12 h-12 flex items-center justify-center transition duration-200 hover:bg-opacity-30' onClick={()=>{}}>
            <ChevronIcon className="w-5 h-5 rotate-90" />
        </button>
        <button className='text-green-500 bg-green-400 bg-opacity-20 rounded-full w-12 h-12 flex items-center justify-center transition duration-200 hover:bg-opacity-30' onClick={()=>{}}>
            <ChevronIcon className="w-5 h-5 -rotate-90" />
        </button>
    </div>
  )
}

export default Pagination