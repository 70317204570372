import React from 'react'
import TicketIcon from '../../elements/icons/TicketIcon'

const TicketCard = ({ticket, selectTicket}) => {
    return (
        <>
            <div onClick={()=>{selectTicket()}} className='cursor-pointer w-full block md:flex flex-row gap-x-4 items-center relative transition duration-200 hover:bg-gray-100 py-3 border-b border-gray-200'>
                <div className='w-full md:w-3/12 text-center'>
                    {/* <QrCodeIcon className="w-full h-32 text-gray-400"/> */}
                    <div className='relative flex items-center justify-center w-full mx-auto animate__animated animate__fadeIn'>
                        <img
                            src={ticket?.qrCode}
                            layout="responsive" 
                            quality={100} 
                            alt='' 
                        />
                    </div>
                    <p className='text-gray-400 mt-3'>Ticket Code: <span className='font-medium text-lg'>{ticket.ticketCode}</span></p>
                </div>

                <div className='w-full md:w-6/12'>
                    {ticket.event?.eventType === 'VERSUS' && <div className='w-2/6 flex items-center gap-x-4 px-8'>
                        <div className='relative w-12 animate__animated animate__fadeIn'>
                            <img
                                src={ticket?.event?.homeSide?.logoUrl}
                                layout="responsive" 
                                quality={100} 
                                alt='' 
                            />
                        </div>
                        <p className='text-sm font-light text-gray-500'>VS</p>
                        <div className='relative w-12 animate__animated animate__fadeIn'>
                            <img
                                src={ticket?.event?.awaySide?.logoUrl}
                                layout="responsive" 
                                quality={100} 
                                alt='' 
                            />
                        </div>
                    </div>}

                    <div className='px-8 mt-2 pt-3'>
                    {ticket.event?.eventType === 'VERSUS' &&  
                        <p className='font-medium text-lg'>{ticket.event.homeSide.name} <span className='text-gray-500 font-light'>Vs </span> {ticket.event?.awaySide?.name}</p> 
                    }  
                    <p className='font-light text-lg'>{ticket.event.name} </p>
 
                        <div className='w-full block md:flex gap-x-3'>
                            <p className='mt-3 text-sm'>Date: {new Date(ticket.event.eventDate).toDateString()}</p>
                            <p className='mt-3 text-sm'>Time: {ticket?.event?.eventTime}</p>
                            <p className='mt-3 text-sm'>Venue: {ticket?.event?.venue?.name}</p>
                        </div>
                    </div>

                </div>
                <div className={`w-2/12 flex items-center justify-center h-44 relative ${ticket.ticketStatus === 'ADMITTED' ? 'bg-green-300' : 'bg-amber-400 bg-opacity-60'}`}>
                    {ticket.ticketStatus === 'ADMITTED' &&<span className='absolute top-0 right-0 z-10'>
                        <TicketIcon className="w-32 text-black text-opacity-10" />
                    </span>}
                    <p className='text-black text-sm z-20'>{ticket.ticketStatus}</p>
                </div>
                <div className='w-2/12'>
                    <p className='mt-3 text-sm'>Purchased on: <br />
                    {new Date(ticket.createdAt).toDateString()}
                    </p>
                </div>
            </div>

            {/* <ModalLayout
                isOpen={ticketModal} 
                closeModal={()=>{setTicketModal(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Admit Guest' 
                dialogTitle='Ticket Details'
                maxWidthClass='max-w-md'
            >
                


            </ModalLayout> */}
        </>
    )
}

export default TicketCard