import React from 'react'
import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Logo from "../../assets/img/icon.png"
import CalendarIcon from '../elements/icons/CalendarIcon'
import CreditCardIcon from '../elements/icons/CreditCardIcon'
import FacilitiesIcon from '../elements/icons/FacilitiesIcon'
import PieChartIcon from '../elements/icons/PieChartIcon'
import SettingsIcon from '../elements/icons/SettingsIcon'
import TicketIcon from '../elements/icons/TicketIcon'
import UsersIcon from '../elements/icons/UsersIcon'

const UserSidebar = () => {
  return (
    <>
      <div className='p-2 border-b border-gray-100'>
        <img src={Logo} alt='' />
      </div>
      <div className='h-full flex flex-col items-center justify-center w-full -mt-24'>
        <NavLink activeclassname="active" to="/admin/dashboard" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="dashboard">
            <PieChartIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="dashboard" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Dashboard
        </ReactTooltip>

        <NavLink activeclassname="active" to="/admin/events" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="events">
            <CalendarIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="events" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Events
        </ReactTooltip>

        <NavLink activeclassname="active" to="/admin/facilities" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="facilities">
            <FacilitiesIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="facilities" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Facilities
        </ReactTooltip>

        <NavLink activeclassname="active" to="/admin/tickets" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="tickets">
            <TicketIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="tickets" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Tickets
        </ReactTooltip>
        
        <NavLink activeclassname="active" to="/admin/transactions" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="transactions">
            <CreditCardIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="transactions" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Transactions
        </ReactTooltip>

        <NavLink activeclassname="active" to="/admin/users" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="users">
            <UsersIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="users" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Users
        </ReactTooltip>

        {/* <NavLink activeclassname="active" to="/admin/settings" className="text-xs my-3 p-2 rounded-full bg-opacity-10 w-inherit flex-row gap-x-4 items-center text-opacity-70 nav-button" data-tip data-for="settings">
            <SettingsIcon className="w-6 h-6 icon" />
        </NavLink>
        <ReactTooltip id="settings" place="right" effect="solid" backgroundColor="#000" textColor='#fff'>
            Settings
        </ReactTooltip> */}

      </div>
    </>
  )
}

export default UserSidebar