import React from 'react'
import Chart from "react-apexcharts";

const BarChart = ({chartData}) => {
    const chartState = { 
        series: [
            // {
            //     name: 'Amount sold',
            //     type: 'area',
            //     data: [14, 12, 10, 14, 8, 10, 6]
            // },
            {
                name: 'Value',
                type: 'column',
                data: chartData?.map((transaction) => {return transaction.amount/100000}).reverse()
                // data: [23, 11, 22, 27, 13, 22, 37]
            }
        ],
        options: {
          chart: {
            fontFamily: 'Outfit, sans-serif',
            foreColor: '#999999',
            toolbar: {
              show: false
            },
            height: 150,
            type: 'line',
            stacked: true,
          },
          grid: {
            show: false  
          },
          stroke: {
            width: [4, 0],
            curve: 'smooth',
            colors: ['#5C54A0']
          },
          plotOptions: {
            bar: {
              columnWidth: '15%',
              borderRadius: 6
            }
          },
          fill: {
            type: "solid",
            // gradient: {
            //     shadeIntensity: 1,
            //     opacityFrom: 0.55,
            //     opacityTo: 0,
            //     stops: [0, 70, 100]
            // }
          },
          legend: {
            show: false
          },
          // labels: ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'],
          labels:chartData?.map((transaction) => {return transaction._id}).reverse(),
          markers: {
            size: 0
          },
          xaxis: {
            type: 'string'
          },
          yaxis: {
              show:true,
            title: {
              text: '',
            },
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            style: {
                fontSize: '12px',
                fontFamily: 'Poppins, sans-serif'
            },
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "NGN" + (y/100000).toFixed(0) + "K";
                }
                return y;
              }
            }
          }
        },
      };
    // }
    return (
        <div id="chart">
            <Chart options={chartState.options} series={chartState.series} type="line" height={300} />
        </div>
    )
}

export default BarChart
