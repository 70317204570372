import React from 'react'
import TextField from '../../components/elements/form/TextField'
import SettingsLayout from '../../components/Layout/SettingsLayout'

const UserProfile = () => {
    
    return (
        <SettingsLayout pageTitle="User Profile">

            {userDetails => (
                <>
                    <h3 className="text-accent text-md font-medium">Your Profile</h3>
                    <div className='w-full flex flex-row gap-x-4 py-4'>
                        <div className="w-full">
                            {/* <label className="text-xs block mb-2">Name</label>
                            <input type='text' value={userDetails.name} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                            <TextField 
                                inputLabel="Full Name"
                                fieldId="full-name"
                                inputType="text"
                                hasError="false"
                                returnFieldValue={()=>{}}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row gap-x-4 py-4'>
                        <div className="w-1/2">
                            {/* <label className="text-xs block mb-2">email</label>
                            <input type='text' value={userDetails.email} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                             <TextField 
                                inputLabel="Email address"
                                fieldId="email"
                                inputType="email"
                                hasError="false"
                                returnFieldValue={()=>{}}
                            />
                        </div>
                        <div className="w-1/2">
                            {/* <label className="text-xs block mb-2">phone</label>
                            <input type='text' value={userDetails.phone} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                             <TextField 
                                inputLabel="Phone Number"
                                fieldId="phone"
                                inputType="text"
                                hasError="false"
                                returnFieldValue={()=>{}}
                            />
                        </div>
                    </div>
                    <div className='my-5 text-right'>
                        <button className="text-right rounded bg-primary px-5 py-4 text-xs text-white transition duration-200 hover:bg-opacity-50">Update Profile</button>
                    </div>
                </>
            )}
        </SettingsLayout>
    )
}

export default UserProfile
