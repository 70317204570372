import React from 'react'
import ArrowNarrowRight from '../../elements/icons/ArrowNarrowRight'
import ClubLogo from '../../../assets/img/lagos-polo-club-logo.png'
import TicketIcon from '../../elements/icons/TicketIcon'
import { Link } from 'react-router-dom'
import EventCard from '../events/EventCard'

const Events = ({events}) => {
  return (
      <div className='p-8'>

            <div className='flex items-center justify-between'>
                <h3 className='font-mefium text-lg text-gray-600 my-4'>Upcoming Events</h3>
                <Link to={`/admin/events`}>
                    <button className='text-black font-medium text-md px-8 transition duration-200 animate__animated animate__fadeInUp flex items-center justify-center gap-x-2 hover:text-red-500'>
                        See all events <ArrowNarrowRight className="w-8 mt-1 h-6" />
                    </button>
                </Link>
            </div>

            
            {events?.length > 0 ? 
                <>
                    <div className='w-full grid grid-cols-3 gap-1 relative z-30'>

                        {events?.map((event, eventIndex) => (
                            <EventCard eventDetails={event} key={eventIndex} />
                        ))}
            
                    </div>
                </> 
                
                : 
                <div className='w-6/12 p-5 rounded mx-auto flex items-center justify-center border border-gray-400 my-12'>
                    <p className='text-sm text-gray-500'> 
                        No events created yet
                    </p>
                </div>
            }
      </div>
  )
}

export default Events