import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PlusIcon from '../../components/elements/icons/PlusIcon'
import SearchIcon from '../../components/elements/icons/SearchIcon'
import Pagination from '../../components/elements/Pagination'
import SuccessMessage from '../../components/elements/SuccessMessage'
import SlideOutModal from '../../components/Layout/SlideOutModal'
import UserLayout from '../../components/Layout/UserLayout'
import NewTicketForm from '../../components/partials/tickets/NewTicketForm'
import TicketCard from '../../components/partials/tickets/TicketCard'
import TicketDetails from '../../components/partials/tickets/TicketDetails'
import { fetchEvents } from '../../store/actions/eventsActions'
import { clearUpdatedTicket, fetchAllTickets } from '../../store/actions/ticketsActions'
import { clearUserDetails } from '../../store/actions/usersActions'

const Tickets = () => {
  const eventsState = useSelector(state => state.events)
  const ticketsState = useSelector(state => state.tickets)
  const [activeTicket, setActiveTicket] = useState(null);
  const [ticketOpen, setTicketOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState(null);
  const dispatch = useDispatch()

  const closeTicket = useCallback(() => {
    setTicketOpen(false)
    dispatch(clearUserDetails())
    dispatch(clearUpdatedTicket())
    setTimeout(() => {
      setActiveTicket(null)
    }, 100);
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchAllTickets())
    dispatch(fetchEvents())

    if(ticketsState.updatedTicket !== null) {
      dispatch(fetchAllTickets())
      setSuccessNotification({
        title: "Successful", 
        text: 'guest has been admitted'
      })
      setTimeout(() => {
        closeTicket()
      }, 200);
    }

    return () => {
        
    };
  }, [closeTicket, dispatch, ticketsState.updatedTicket]);

  const [creatingTicket, setCreatingTicket] = useState(false);

  const [ticketPayload, setTicketPayload] = useState({});
  
  const closeNewTicket = () => {
    setCreatingTicket(false)
    setTimeout(() => {
      setTicketPayload({})
    }, 100);
  }

  const openTicket = (ticketIndex) => {
    setActiveTicket(ticketIndex)
    setTimeout(() => {
      setTicketOpen(true)
    }, 200);
  }

  return (
    <UserLayout pageTitle="Tickets">
    {successNotification!==null && 
      <SuccessMessage toggleClose={()=>{setSuccessNotification(null)}} messageTitle={successNotification.title} messageText={successNotification.text}/>
    }
      <div className='w-full ml-auto mr-auto min-h-screen p-12'> 
        <div className='w-full flex items-center justify-between mb-12'>
          <div>
            <h4 className='font-medium text-lg mb-3 text-gray-400'>Tickets to Events at Murtala Square</h4>
            <div className='w-2/3'>
              <p className='text-gray-500 my-3 text-sm'>
                Below is a list of Tickets booked at Murtala Muhammed Square. you can create a new ticket/booking by clicking on the button to the right or click on an ticket below to see attendee details and admit the user.
              </p>
            </div>
          </div>

          <div className='flex flex-row gap-x-4 items-center'>
            <button onClick={()=>{setCreatingTicket(true)}} className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded'>
              <PlusIcon className="text-white w-6 h-6" /> Create new ticket/booking
            </button>
          </div>
        </div>

        <div className="relative rounded shadow-sm border border-gray-500 py-3 px-4 bg-white border-opacity-30 w-1/2 mb-12">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm mt-1">
                <SearchIcon classes="h-6 w-6 text-gray-500" />
            </span>
          </div>
          <input type="text" className="bg-white block text-xs w-full focus:border-transparent focus:outline-none pl-7" placeholder="Search by ticket code or guest name" />
        </div>

        {ticketsState.tickets.map((ticket, ticketIndex)=>(
          <div key={ticketIndex} className='w-full'>
            <TicketCard ticket={ticket} selectTicket={()=>{openTicket(ticketIndex)}} />
          </div>
        ))}

        <div className='mt-8'>
          <Pagination />
        </div>
      
      </div>

      {activeTicket!==null&& 
        <SlideOutModal isOpen={ticketOpen} closeFunction={()=>{closeTicket()}} title={`Ticket Details`} subTitle={`See details of the ticket below`} >
            <TicketDetails ticket={ticketsState.tickets[activeTicket]} />
        </SlideOutModal>
      }

      {!eventsState.fetchingEvents && eventsState.events?.events?.length > 0 && 
          <SlideOutModal isOpen={creatingTicket} closeFunction={()=>{closeNewTicket()}} title={`Create a new ticket`} subTitle={`Provide details of the new ticket in the form below`} >
            <NewTicketForm 
              events={eventsState.events}
              ticketPayload={ticketPayload}
              setTicketPayload={(data)=>{setTicketPayload(data)}}
            />
          </SlideOutModal>
      }
    </UserLayout>
  )
}

export default Tickets