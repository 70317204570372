import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ArrowNarrowRight from '../../../components/elements/icons/ArrowNarrowRight';
import NotAllowedIcon from '../../../components/elements/icons/NotAllowedIcon';
import PencilSquareIcon from '../../../components/elements/icons/PencilSquareIcon';
import PlusIcon from '../../../components/elements/icons/PlusIcon';
import InlinePreloader from '../../../components/elements/InlinePreloader';
import MediaSlider from '../../../components/elements/MediaSlider';
import SuccessMessage from '../../../components/elements/SuccessMessage';
import SlideOutModal from '../../../components/Layout/SlideOutModal';
import UserLayout from '../../../components/Layout/UserLayout'
import NewEventForm from '../../../components/partials/events/NewEventForm';
import EditFacility from '../../../components/partials/facilities/EditFacility';
import EditSubscriptionPlan from '../../../components/partials/facilities/EditSubscriptionPlan';
import NewSubscriptionPlan from '../../../components/partials/facilities/NewSubscriptionPlan';
import { fetchEventsForFacility } from '../../../store/actions/eventsActions';
import { clearCreatedPlan, clearUpdatedPlan, fetchAllFacilities, fetchFacilitySubscriptionPlans, fetchSingleFacility, updateFacilityDetails } from '../../../store/actions/facilitiesActions';
import { isOdd } from '../../../utils/utils';

const Facility = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const eventsState = useSelector(state => state.events)
  const facilitiesState = useSelector(state => state.facilities)
  const [facilityUpdatePayload, setFacilityUpdatePayload] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    dispatch(fetchSingleFacility(id))
    dispatch(fetchAllFacilities())
    dispatch(fetchEventsForFacility({
      page: 1,
      perPage: 5
    }, id))
    dispatch(fetchFacilitySubscriptionPlans({
      page: 1,
      perPage: 5
    }, id))

    if(facilitiesState.createdSubscriptionPlan !== null) {
      setSuccessMessage('Subscription plan created successfully!')
      dispatch(clearCreatedPlan())
      closeCreatingPlan()
    }

    if(facilitiesState.updatedSubscriptionPlan !== null) {
      setSuccessMessage('Subscription plan updated successfully!')
      dispatch(clearUpdatedPlan())
      closePlan()
    }

    return () => {
      
    };
  }, [dispatch, facilitiesState.createdSubscriptionPlan, id, facilitiesState.updatedSubscriptionPlan]);


  // const cancelEvent = () => {
  //   setEventUpdatePayload({
  //     cancelled: true,
  //     sellingTickets: false,
  //     published: false
  //   })
  //   updateEvent()
  // }

  const [editingPlan, setEditingPlan] = useState(false);
  const [activePlan, setActivePlan] = useState(null);

  const openPlan = (planIndx) => {
    setActivePlan(planIndx)

    setTimeout(() => {
      setEditingPlan(true)
    }, 200);
  }

  const closePlan = () => {
    setEditingPlan(false)
    setTimeout(() => {
      setActivePlan(null)
    }, 200);
  }

  const [editingFacility, setEditingFacility] = useState(false);

  const [creatingPlan, setCreatingPlan] = useState(false);
  const [newPlanPayload, setNewPlanPayload] = useState({});

  const closeCreatingPlan = () => {
    setCreatingPlan(false)
    setNewPlanPayload({})
  }

  const [planPayload, setPlanPayload] = useState({});
  const [facilityPayload, setFacilityPayload] = useState({});

  const [creatingEvent, setCreatingEvent] = useState(false);
  const [eventPayload, setEventPayload] = useState({});

  const updateFacility = () => {
    if(!facilityUpdatePayload || Object.keys(facilityUpdatePayload).length === 0) {
      return
    }
    dispatch(updateFacilityDetails({...facilityUpdatePayload, ...{facilityId: id}}))
  }

  const changePublishStatus = () => {
    setFacilityUpdatePayload({published: !facilitiesState.facilityDetails.published})
    setTimeout(() => {
      updateFacility()
    }, 200);
  }

  return (
    <UserLayout pageTitle="Dashboard">
      {successMessage!==null && 
        <SuccessMessage toggleClose={()=>{setSuccessMessage(null)}} messageTitle={'Success'} messageText={successMessage}/>
      }

      {facilitiesState.fetchingFacilityDetails === true ?
        <div className='w-full py-12 flex items-center justify-center'>
            {/* {eventsState.fetchingEvents.toString()} */}
            <InlinePreloader />
        </div>

      :

        <div className=''>
          <div className='w-full flex flex-row gap-x-8'>
            <div className='w-9/12 pb-6 px-12 mt-6'>
                <div className='border-b border-gray-200 pb-6 flex justify-between'>
                  <div className='w-full'>
                    <h3 className='font-medium text-2xl text-gray-400'>{facilitiesState?.facilityDetails?.name}</h3>
                  </div>

                  <div className='w-9/12 flex flex-row-reverse items-center gap-x-6'>
                    {/* {(!eventsState.eventDetails?.cancelled || eventsState.eventDetails?.cancelled === false) &&  <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded' onClick={()=>{setEditingEvent(true)}}>
                      <PencilSquareIcon className="text-white w-6 h-6" /> Create Su
                    </button>} */}

                    <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded' onClick={()=>{setEditingFacility(true)}}>
                      <PencilSquareIcon className="text-white w-6 h-6" /> Edit Facility Details
                    </button>

                    <div className='pr-4'>
                      <label className="text-sm text-gray-500 font-sofia-pro block w-full mb-2">Published</label>
                      <Switch
                          checked={facilitiesState?.facilityDetails?.published === true}
                          onChange={()=>changePublishStatus()}
                          className={`${
                            facilitiesState?.facilityDetails?.published === true ? 'bg-blue-200' : 'bg-gray-400'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          <span className="sr-only">Enable on contact page</span>
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                                facilitiesState?.facilityDetails?.published === true ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                              } inline-block w-3 h-3 transform rounded-full`}
                          />
                      </Switch>
                    </div>

                   <button className='px-4 py-3 flex items-center gap-x-2 text-sm rounded text-red-400' onClick={()=>{}}>
                    <NotAllowedIcon className="text-red-400 w-6 h-6" /> Delete Facility
                    </button>
                  </div>

                </div>
                
                <div className='w-full mx-auto'>
                  <div className='w-full xl:flex items-end justify-between gap-x-12'>
                    <div className='w-full px-8 ml-auto mr-auto min-h-screen py-8'> 
                      
                      <div className='w-full mr-auto z-20 mb-8'>
                        <h4 className='font-medium text-lg mb-3 text-gray-400 uppercase'>{facilitiesState?.facilityDetails?.name}</h4>
                        <h3 className='text-3xl text-gray-600 font-outfit pr-4 mb-5'>{facilitiesState?.facilityDetails?.summary}</h3>

                        <p className='text-gray-800 mt-4 lg:animate__animated lg:animate__fadeInUp'>{facilitiesState?.facilityDetails?.description} </p>

                      </div>

                      <div className='py-12'>
                        <MediaSlider slides={facilitiesState?.facilityDetails?.media} />
                      </div>

                      <div>
                        <div className='w-full flex flex-row justify-between mr-auto z-20 mb-12'>
                          <div className='w-1/2'>
                            <h3 className='text-3xl text-gray-600 font-outfit pr-4 mb-5'>Membership and Subscription Plans</h3>
                          </div>
                          <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded' onClick={()=>{setCreatingPlan(true)}}>
                            <PlusIcon className="text-white w-5 h-5" /> Create new subscription plan
                          </button>
                        </div>

                        {facilitiesState?.fetchingFacilityPlans === true ? 
                          <div className='w-full py-12 flex items-center justify-center'>
                              {/* {eventsState.fetchingEvents.toString()} */}
                              <InlinePreloader />
                          </div>
                        :

                        facilitiesState?.facilityDetails?.facilityPlans?.length === 0 ?
                          <div className='w-10/12 lg:w-5/12 mx-auto my-8 p-5 rounded bg-gray-200 text-gray-600 text-sm'>
                            <p>Sorry, no subscription plans listed at the moment for this facility</p>
                          </div>
                          :
                          <div className='w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 relative z-30'>

                            {facilitiesState?.facilityPlans?.map((plan, planIndex) => (
                              <div key={planIndex} className={`w-full ${isOdd(planIndex) && isOdd(planIndex) === 1 ? 'bg-gray-200' : 'bg-gray-100'} relative`} style={{height: '70vh'}}>
                                <button onClick={()=>{openPlan(planIndex)}} className='mt-8 text-sm text-md px-8 transition duration-200 flex items-center justify-center gap-x-2 text-gray-500 hover:text-black relative'>
                                  <PencilSquareIcon className="w-5 h-5" /> Edit plan
                                </button>

                                <button onClick={()=>{}} className='mt-8 text-sm text-md px-8 transition duration-200 flex items-center justify-center gap-x-2 text-gray-400 hover:text-black'>
                                  <NotAllowedIcon className="w-5 h-5" /> Delete plan
                                </button>

                                {(!plan?.published || plan?.published === false) && 
                                  <span className='font-outfit absolute top-6 right-5 ml-3 text-sm border rounded border-gray-500 text-gray-500 px-2 py-1'>Draft Plan</span>
                                }

                                <div className='px-8 mt-12 animate__animated animate__fadeInUp'>
                                  <p className='font-medium text-xl'>{plan.name} </p>
                                  <p className='text-sm font-light text-gray-500 my-4'>{plan.description}</p>
                                </div>

                                <div className='px-8 mt-4 pt-3 absolute bottom-12'>
                                  <p className='mt-3 text-gray-400'>From <span className='text-2xl block text-blue-800'>NGN {(plan.durationPrice/100).toLocaleString()}</span> per {plan.duration} days for {plan.admittanceCount} subscriber(s) </p>
                                </div>

                              </div>
                            ))}
                          </div>

                        }

                      </div>
                    
                    </div>

                  </div>
                

              </div>

            </div>

            <div className='w-3/12 bg-gray-100 h-screen p-10 fixed overflow-y-scroll right-0'>
              <div className='py-6 border-b border-gray-400'>
                <div className=''>
                  <div>
                    <h3 className='font-medium text-lg text-gray-700'>Upcoming/Recent Events</h3>
                    <p className='text-sm mt-2 text-gray-500 mb-6'>Most recent events held at this facility</p>
                  </div>
                  <button onClick={()=>{setCreatingEvent(true)}} className='px-3 py-2 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded'>
                    <PlusIcon className="text-white w-4 h-4" /> Create event
                  </button>
                </div>

                {eventsState?.fetchingFacilityEvents ?  

                  <div className='w-full py-12 flex items-center justify-center'>
                      {/* {eventsState.fetchingEvents.toString()} */}
                      <InlinePreloader />
                  </div>
                  
                :
                eventsState?.facilityEvents?.length > 0 ?
                  <>
                    {eventsState?.facilityEvents?.map((event, eventIndex) => (
                      <div key={eventIndex} className='w-full bg-gray-100 relative border-t border-b py-3' 
                      // style={{height: '70vh'}}
                      >

                        {event?.cancelled === true && 
                            <span className='font-outfit absolute top-4 right-4 ml-3 text-sm border rounded border-red-500 text-red-500 px-2 py-1'>Event Cancelled</span>
                        }
                        {(!event?.cancelled || event?.cancelled === false) && (!event?.published || event?.published === false) && 
                            <span className='font-outfit absolute top-4 right-4 ml-3 text-sm border rounded border-gray-500 text-gray-500 px-2 py-1'>Draft Event</span>
                        }

                        {event.eventType === 'VERSUS' && <div className='w-full flex items-center gap-x-4 px-8 mt-5 pt-5'>
                            <div className='relative w-1/5 animate__animated animate__fadeIn'>
                                {event.homeSide.logoUrl && event.homeSide.logoUrl !== '' ? 
                                    <div className='rounded-md border border-gray-300 w-[45px] h-[45px] mx-auto' style={{backgroundImage: `url(${ event.homeSide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    </div>
                                    :
                                    <div className='w-[40px] h-[40px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                                }
                            </div>
                            <p className='text-sm font-light text-gray-500 text-center w-3/5'>VS</p>
                            <div className='relative w-1/5 animate__animated animate__fadeIn mx-auto'>
                                {event.awaySide.logoUrl && event.awaySide.logoUrl !== '' ? 
                                    <div className='rounded-md border border-gray-300 w-[45px] h-[45px]' style={{backgroundImage: `url(${ event.awaySide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    </div>
                                    :
                                    <div className='w-[40px] h-[40px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                                }
                            </div>
                        </div>}

                        <div className={`px-8 mt-4 pt-3`}>
                            {event.eventType === 'VERSUS' && <div className='w-full flex gap-x-2'>
                                <p className='font-medium text-lg'>{event.homeSide.name} </p>
                                <p className='text-gray-500 font-light'>Vs</p>
                                <p className='font-medium text-lg'>{event.awaySide.name} </p>
                            </div> 
                            }
                            <p className='text-lg mt-5'>{event.name} <span></span> </p>
                            
                            <p className='mt-3 text-sm'> {new Date(event.eventDate).toDateString()} {event.eventTime && <span>- {event.eventTime}</span>}</p>
                        </div>

                        <Link to={`single/${event._id}`}>
                            <button className='mt-4 text-black font-medium text-md px-8 py-4 transition duration-200 animate__animated animate__fadeInUp flex items-center justify-center gap-x-2 hover:text-red-500'>
                                See Details <ArrowNarrowRight className="w-8 mt-1 h-6" />
                            </button>
                        </Link>

                        </div>
                    ))}
                  </>
                  :

                  <div className='w-11/12 rounded-md border border-gray-400 text-center p-5'>
                    <p className='text-sm text-gray-600'>No event created yet for this facility. You can create a new event for this facility now by clicking oon the "create event" button above</p>
                  </div>
                
                }

              </div>
            </div>
          </div>

        </div>

    }
    {!facilitiesState.fetchingFacilityDetails && facilitiesState?.facilityDetails &&
      <SlideOutModal isOpen={editingFacility} closeFunction={()=>{setEditingFacility(false)}} title={`Update Facility Details`} subTitle={``} >
        <EditFacility
          facilityPayload={facilityPayload}
          setFacilityPayload={(data)=>{setFacilityPayload(data)}}
          facilityDetails={facilitiesState?.facilityDetails}
        />
      </SlideOutModal>
    }

    {!facilitiesState.fetchingFacilityDetails && 
      <SlideOutModal isOpen={creatingPlan} closeFunction={()=>{closeCreatingPlan()}} title={`Create a new subscription plan for ${facilitiesState?.facilityDetails?.name}`} subTitle={``} >
        <NewSubscriptionPlan 
          newPlanPayload={newPlanPayload}
          setNewPlanPayload={(data)=>{setNewPlanPayload(data)}}
          facilityId={id}
        />
      </SlideOutModal>
    }

    {!facilitiesState.fetchingFacilityDetails && activePlan !== null && 
      <SlideOutModal isOpen={editingPlan} closeFunction={()=>{closePlan()}} title={`Edit subscription plan`} subTitle={`Change the details you need to for the plan elow and save`} >
        <EditSubscriptionPlan
          planPayload={planPayload}
          setPlanPayload={(data)=>{setPlanPayload(data)}}
          planDetails={facilitiesState?.facilityPlans[activePlan]}
        />
      </SlideOutModal>
    }

    {!facilitiesState.fetchingFacilities && facilitiesState.facilities.length > 0 && 
        <SlideOutModal isOpen={creatingEvent} closeFunction={()=>{setCreatingEvent(false)}} title={`Create a new event`} subTitle={`Provide details of the upcoming event in the form below`} >
          <NewEventForm 
              facilities={facilitiesState.facilities} 
              eventPayload={eventPayload}
              setEventPayload={(data)=>{setEventPayload(data)}}
              facilityId={id}
          />
        </SlideOutModal>
    }
</UserLayout>
  )
}

export default Facility
