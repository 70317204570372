import { UPLOADED_FILE, UPLOADING_FILE, UPLOAD_FILE_ERROR } from "../types"

const initialState = {
    uploadingFile: false,
    uploadedFile: null,
    fileError: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case UPLOADING_FILE:
        return {
            ...state,
            uploadingFile:action.payload
        }
        case UPLOADED_FILE:
        return {
            ...state,
            uploadingFile:false,
            uploadedFile: action.payload
        }
        case UPLOAD_FILE_ERROR:
        return {
            ...state,
            uploadingFile: false,
            fileError: action.payload
        }

        
        default: return state
    }

}