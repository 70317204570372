// import logo from './logo.svg';
import './App.css';
import {
  Routes,
  Route,
  Navigate 
} from 'react-router-dom';
import Login from './pages/Login';
import ScrollToTop from './components/Layout/ScrollToTop';
import AccountActivation from './pages/AccountActivation';
import ResetRequest from './pages/password-reset/ResetRequest';
import ResetPassword from './pages/password-reset/ResetPassword';
import Admin from './pages/Admin';
import Dashboard from './pages/admin/Dashboard';
import Facilities from './pages/admin/facilities/Facilities';
import Events from './pages/admin/events/Events';
import Tickets from './pages/admin/Tickets';
import Settings from './pages/settings/Settings';
import UserProfile from './pages/settings/UserProfile';
import Team from './pages/settings/Team';
import Security from './pages/settings/Security';
import Transactions from './pages/admin/Transactions';
import Event from './pages/admin/events/Event';
import Facility from './pages/admin/facilities/Facility';

import { Provider } from "react-redux";
import store from "./store/store";
import ErrorNotifier from './components/elements/ErrorNotifier';
import Users from './pages/admin/users/Users';

function App() {
  return (
    <main>
      <Provider store={store}>
          <ScrollToTop>
          <ErrorNotifier />
          
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/signup/:invitationCode" exact element={<AccountActivation />} />
            <Route path="/password-reset" exact element={<ResetRequest />} />
            <Route path="/password-reset/:resetCode" exact element={<ResetPassword />} />
            <Route path="/admin" element={<Admin />}>
              <Route path="/admin/" element={<Navigate replace to="/admin/dashboard" />} />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/facilities" element={<Facilities />} />
              <Route path="/admin/facilities/single/:id" element={<Facility />} />
              <Route path="/admin/events" element={<Events />} />
              <Route path="/admin/events/single/:id" element={<Event />} />
              <Route path="/admin/tickets" element={<Tickets />} />
              <Route path="/admin/transactions" element={<Transactions />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/settings" element={<Settings />}>
                <Route path="/admin/settings/" element={<Navigate replace to="/admin/settings/user-profile" />} />
                <Route path="/admin/settings/user-profile" element={<UserProfile />} />
                <Route path="/admin/settings/team" element={<Team />} />
                <Route path="/admin/settings/security" element={<Security />} />
              </Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </Provider>
    </main>
  );
}

export default App;
