import { Switch } from '@headlessui/react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewEvent } from '../../../store/actions/eventsActions';
import { CREATING_EVENT, ERROR } from '../../../store/types';
import { authHeader, eventTypes } from '../../../utils/utils';
import AutocompleteSelect from '../../elements/form/AutocompleteSelect';
import CurrencyField from '../../elements/form/CurrencyField';
import DateField from '../../elements/form/DateField';
import FileUpload from '../../elements/form/FileUpload';
import FormButton from '../../elements/form/FormButton';
import SelectField from '../../elements/form/SelectField';
import TextareaField from '../../elements/form/TextareaField';
import TextField from '../../elements/form/TextField'
import TimeInputField from '../../elements/form/TimeInputField';

const NewEventForm = ({facilities, eventPayload, setEventPayload, facilityId}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()
    const eventsState = useSelector(state => state.events)

    const [sellingTickets, setSellingTickets] = useState(false);
    // const [file, setFile] = useState(null);
    const [homeLogo, setHomeLogo] = useState(null);
    const [awayLogo, setAwayLogo] = useState(null);
    const [eventCover, setEventCover] = useState(null);
    const [publishEvent, setPublishEvent] = useState(false);


    const validateForm = () => {
        let errors = {}
        
        if(!eventPayload.name || eventPayload.name === '') {
            errors.name = true
        }
    
        if(!eventPayload.eventDate || eventPayload.eventDate === '') {
            errors.eventDate = true
        }
    
        if(!eventPayload.eventTime || eventPayload.eventTime === '') {
            errors.eventTime = true
        }
    
        if(!eventPayload.description || eventPayload.description === '') {
            errors.description = true
        }
    
        if(!eventPayload.eventType || eventPayload.eventType === '') {
            errors.eventType = true
        }
    
        if(eventPayload.eventType === 'VERSUS' && (!eventPayload.homeName || eventPayload.homeName === '')) {
            errors.homeName = true
        }
    
        if(eventPayload.eventType === 'VERSUS' && (!eventPayload.awayName || eventPayload.awayName === '')) {
            errors.awayName = true
        }
    
        if(!eventPayload.venue || eventPayload.venue === '') {
            errors.venue = true
        }
    
        // if(!eventPayload.ticketPrice || eventPayload.ticketPrice === '') {
        //     errors.ticketPrice = true
        // }
    
        setValidationErrors(errors)
        return errors
    }

    const createEvent = (homeLogoUrl, awayLogoUrl, media) => {
        const payload = {
            name: eventPayload.name,
            description: eventPayload.description,
            venue: eventPayload.venue,
            eventDate: eventPayload.eventDate,
            eventType: eventPayload.eventType,
            ticketPrice: eventPayload.ticketPrice,
            eventTime: eventPayload.eventTime,
            sellingTickets: sellingTickets,
            published: publishEvent
        }

        if(media && media.length > 0) {
            payload.media = media
        }

        if(eventPayload.homeName) {
            payload.homeSide = {
                name: eventPayload.homeName,
                logoUrl: homeLogoUrl
            }
        }

        if(eventPayload.awayName) {
            payload.awaySide = {
                name: eventPayload.awayName,
                logoUrl: awayLogoUrl
            }
        }

        dispatch(createNewEvent(payload))   
    }

    const triggerCreateEvent = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please fill in all required field(s) highlighted below'
                }}}
            })
            return
        }

        let homeLogoUrl = ''
        let awayLogoUrl = ''
        let eventMedia = ''

        if(homeLogo && homeLogo !== '') {
            homeLogoUrl = await uploadFile(homeLogo)
        }

        if(awayLogo && awayLogo !== '') {
            awayLogoUrl = await uploadFile(awayLogo)
        }

        if(eventCover && eventCover !== '') {
            eventMedia = [ 
                {
                    itemType: "IMAGE",
                    itemUrl: await uploadFile(eventCover)
                }
            ] 
        }

        createEvent(homeLogoUrl, awayLogoUrl, eventMedia)

        // if(file && file !== '') {
        //     uploadFile({file})
        // } else {
        // }
    }

    const uploadFile = async (file) => {
        const headers = new Headers();
        headers.append("Authorization",  authHeader().Authorization);
        try {

            var data = new FormData();
            data.append('file', file.file)
            dispatch({
                type: CREATING_EVENT,
                payload: true,
            });
            const upload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: data,
            });
            const response = await upload.json();
            console.log(response)
            if(response.status === false) {
                dispatch({
                    type: ERROR,
                    // payload: error,
                    error: {response: {data: {
                            message: response.message
                        }}}
                });
                dispatch({
                    type: CREATING_EVENT,
                    payload: false,
                });
                return
            }
            // setTimeout(() => {
            return response.data.file
            // }, 200);

        } catch (error) {
            console.log("err",error);
            dispatch({
                type: ERROR,
                // payload: error,
                error,
            });
            dispatch({
                type: CREATING_EVENT,
                payload: false,
            });
        }
    }

    return (
        <>
            <div className='my-8 w-full'>
                <TextField
                    inputLabel="Event name" 
                    fieldId="event-name" 
                    inputType="text" 
                    hasError={validationErrors.name} 
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{name: value}})}}
                />
            </div>  

            <div className='my-8 w-full flex gap-x-4 justify-between'>
                <div className='w-8/12'>
                    <DateField
                        inputLabel="Event Date" 
                        fieldId="event-date" 
                        hasError={validationErrors.date} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventDate: value}})}}
                    />
                </div>
                <div className='w-4/12'>
                    <TimeInputField
                        inputLabel="Event Time" 
                        fieldId="event-time" 
                        hasError={validationErrors.time} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventTime: value}})}}
                    />
                </div>
            </div>  

            <div className='my-8 w-full'>
                <TextareaField
                    inputLabel="About the event" 
                    fieldId="event-description" 
                    inputType="text" 
                    preloadValue=""
                    hasError={validationErrors.description} 
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{description: value}})}}
                />
                <label className='text-sm block w-full pt-3'>Some details about the event - let people know what to expect</label>

            </div>  

            <div className='my-8 w-full'>
                <SelectField
                    selectOptions = {eventTypes}
                    inputLabel = "Type of event"
                    titleField = "label"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event-type"
                    preSelected={''}
                    preSelectedLabel='value'
                    hasError = {false}
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{eventType: value.value}})}}
                />
            </div>  

            {eventPayload?.eventType === 'VERSUS' && <div className='p-5 bg-white border border-gray-200 rounded-md'>
                <p className='text-black text-sm'><span className='font-medium'>Versus</span> events require a home and an away team, please provide their details here</p>

                <p className='text-black mt-3 mb-5'>Home Team</p>

                <div className='my-8 w-full'>
                    <TextField
                        inputLabel="Team name" 
                        fieldId="home-team-name" 
                        inputType="text" 
                        hasError={validationErrors.homeName} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{homeName: value}})}}
                    />
                </div>  

                <div className='my-8 w-full'>
                    <FileUpload
                        hasError={false}
                        fieldLabel="Home team logo"
                        returnFileDetails={(details)=>{setHomeLogo(details)}}
                        acceptedFormats={['png', 'jpg', 'jpeg']}
                    />
                </div>

                <p className='text-black mt-3 mb-5'>Away Team</p>

                <div className='my-8 w-full'>
                    <TextField
                        inputLabel="Team name" 
                        fieldId="away-team-name" 
                        inputType="text" 
                        hasError={validationErrors.awayName} 
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{awayName: value}})}}
                    />
                </div>  

                <div className='my-8 w-full'>
                    <FileUpload
                        hasError={false}
                        fieldLabel="Away team logo"
                        returnFileDetails={(details)=>{setAwayLogo(details)}}
                        acceptedFormats={['png', 'jpg', 'jpeg']}
                    />
                </div>

            </div>}
            <div className='my-8 w-full'>
                <AutocompleteSelect
                    selectOptions = {facilities}
                    inputLabel = "Event venue"
                    titleField = "name"
                    displayImage = {false}
                    imageField = ""
                    fieldId = "event-venue"
                    preSelected={facilityId && facilityId !== '' ? facilityId : ''}
                    preSelectedLabel='_id'
                    disabled={facilityId && facilityId !== ''}
                    hasError = {validationErrors && validationErrors.venue}
                    returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{venue: value._id}})}}
                />
                <label className='text-sm block w-full pt-3'>Where will this event take place? select from a list of Murtalasquare facilities above</label>
            </div>  

            <div className='my-8 w-full flex gap-x-4 justify-between'>
                <div className='w-8/12'>
                    <CurrencyField 
                        inputType="number" 
                        fieldId="limit"
                        inputLabel="Ticket price" 
                        currencySymbol={'₦'}
                        hasError={validationErrors && validationErrors.ticketPrice} 
                        preloadValue={''}
                        returnFieldValue={(value)=>{setEventPayload({...eventPayload, ...{ticketPrice: value*100}})}}
                        maximumAmount={50000}
                    /> 
                    <label className='text-sm block w-full pt-3'>Ignore this if the event is free</label>

                </div>
                <div className='w-4/12 text-right'>
                    <label className="text-sm text-gray-500 font-sofia-pro block w-full mb-2">Currently Selling?</label>
                    <Switch
                        checked={sellingTickets}
                        onChange={setSellingTickets}
                        className={`${
                            sellingTickets ? 'bg-blue-200' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                sellingTickets ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
            </div> 

            <div className='my-8 w-full'>
                <FileUpload
                    hasError={false}
                    fieldLabel="Event cover image"
                    returnFileDetails={(details)=>{setEventCover(details)}}
                    acceptedFormats={['jpg', 'jpeg', 'png']}
                />
            </div>

            <div className='my-8 w-full'>
                <div className='full text-right mb-6'>
                    <label className="text-sm text-gray-500 font-sofia-pro w-full mb-2 mr-4">Toggle this switch to publish the event right away</label>
                    <Switch
                        checked={publishEvent}
                        onChange={setPublishEvent}
                        className={`${
                            publishEvent ? 'bg-blue-200' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Enable on contact page</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                publishEvent ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
                <FormButton buttonLabel="Create Event" buttonAction={()=>{triggerCreateEvent()}} processing={eventsState.creatingEvent} />
            </div>
        </>
    )
}

export default NewEventForm