import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CalendarIcon from '../../components/elements/icons/CalendarIcon'
import TicketIcon from '../../components/elements/icons/TicketIcon'
import UsersIcon from '../../components/elements/icons/UsersIcon'
import InlinePreloader from '../../components/elements/InlinePreloader'
import UserLayout from '../../components/Layout/UserLayout'
import BarChart from '../../components/partials/BarChart'
import Events from '../../components/partials/dashboard/Events'
import { fetchEvents } from '../../store/actions/eventsActions'
import { fetchSystemStats } from '../../store/actions/statsActions'
import { fetchAllTickets } from '../../store/actions/ticketsActions'
import { userDetails } from '../../utils/utils'

const Dashboard = () => {
    const eventsState = useSelector(state => state.events)
    const statsState = useSelector(state => state.stats)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchEvents({
            page: 1,
            perPage: 25
        }))
        dispatch(fetchAllTickets())
        dispatch(fetchSystemStats())
    }, [dispatch]);

    return (
        <UserLayout pageTitle="Dashboard">
            <div className=''>

                <div className='w-full flex flex-row gap-x-8'>
                    <div className='w-9/12 border-b border-gray-200 pb-6 px-12 mt-12'>
                        <h3 className='font-medium text-2xl text-gray-400'>Welcome {(userDetails()?.userInfo?.name?.split(' ')[0])}!</h3>

                        {statsState?.loadingStats === true ?
                            <div className='w-full py-12 flex items-center justify-center'>
                                <InlinePreloader />
                            </div>

                            :

                            <div className='flex flex-row gap-x-8 mt-12'>
                                <div className='relative w-4/12 flex gap-x-4'>
                                    <TicketIcon className="w-12 h-12 bg-mms-green bg-opacity-10 text-mms-green rounded-lg p-3" />
                                    <div>
                                        <h3 className='font-courier-prime text-4xl text-gray-600'><span className='text-sm'>NGN </span>{(statsState?.stats?.ticketSalesThisMonth/100)?.toLocaleString() || 0}<span className='text-sm'></span></h3>
                                        <p className='text-sm mt-3'>From <span className='text-xl font-courier-prime text-gray-500'>{statsState?.stats?.ticketSoldThisMonth?.toLocaleString() || 0}</span> ticket sales for <span className='text-xl font-courier-prime text-gray-500'>{statsState?.stats?.totalEventsThisMonth?.toLocaleString() || 0}</span> events this month</p>
                                        <p className='text-sm mt-1 text-mms-green'>NGN{(statsState?.stats?.ticketSalesThisYear/100)?.toLocaleString() || 0} total this year.</p>
                                        <div className='mt-2 block'>
                                            <Link to="/admin/events" className='text-mms-blue text-sm'>See all tickets</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='relative w-4/12 flex gap-x-4'>
                                    <CalendarIcon className="w-14 h-12 bg-mms-green bg-opacity-10 text-mms-green rounded-lg p-3" />
                                    <div>
                                        <h3 className='font-courier-prime text-4xl text-gray-600'>{(statsState?.stats?.totalEventsThisMonth)?.toLocaleString() || 0}</h3>
                                        <p className='text-sm mt-3'>Events created this month. 
                                        {/* <span className='text-xl font-courier-prime text-gray-500'>5</span> events closed at full capacity. <span className='text-xl font-courier-prime text-gray-500'>6</span> pending */}
                                        </p>
                                        <div className='mt-2 block'>
                                            <Link to="/admin/events" className='text-mms-blue text-sm'>See all events</Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='relative w-4/12 flex gap-x-4'>
                                    <UsersIcon className="w-14 h-12 bg-mms-green bg-opacity-10 text-mms-green rounded-lg p-3" />
                                    <div>
                                        <h3 className='font-courier-prime text-4xl text-gray-600'>{statsState?.stats?.totalSubscriptions?.toLocaleString() || 0}</h3>
                                        <p className='text-sm mt-3'>User subscriptions across <span className='text-xl font-courier-prime text-gray-500'>{statsState?.stats?.totalFacilities?.toLocaleString() || 0}</span> Facilities. <span className='text-xl font-courier-prime text-gray-500'>{statsState?.stats?.subscriptionsThisMonth?.toLocaleString() || 0}</span> new subscriptions this month</p>
                                        <div className='mt-2 block'>
                                            <Link to="/admin/events" className='text-mms-blue text-sm'>See all users</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        <div className='w-full'>
                            {eventsState?.fetchingEvents === true ?
                                <div className='w-full py-12 flex items-center justify-center'>
                                    <InlinePreloader />
                                </div>

                                :

                                <Events events={eventsState?.events?.events?.filter((event)=>{return event.published === true}).slice(0, 3)} />
                            }
                        </div>
                    </div>

                    <div className='w-3/12 bg-gray-100 h-screen p-10 fixed right-0'>
                        <h3 className='font-mefium text-lg text-gray-700'>Revenue Stats</h3>
                        <p className='text-sm mt-2 text-gray-500 mb-6'>This is your revenue performance for the selected time period across all revenue streams. <br />Use the switch below to toggle the time period and the dropdown to select revenue source</p>
                        
                        <BarChart chartData={statsState?.stats?.transactionsByMonth} />
                    </div>
                </div>

            </div>
        </UserLayout>
    )
}

export default Dashboard