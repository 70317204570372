import React, { useState, useEffect } from 'react'
import UserLayout from '../../../components/Layout/UserLayout'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import SlidersIcon from '../../../components/elements/icons/SlidersIcon'
import SlideOutModal from '../../../components/Layout/SlideOutModal'
import NewEventForm from '../../../components/partials/events/NewEventForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllFacilities } from '../../../store/actions/facilitiesActions'
import { clearCreatedEvent, fetchEvents } from '../../../store/actions/eventsActions'
import InlinePreloader from '../../../components/elements/InlinePreloader'
import EventCard from '../../../components/partials/events/EventCard'
import SuccessMessage from '../../../components/elements/SuccessMessage'


const Events = () => {
    const [creatingEvent, setCreatingEvent] = useState(false);
    const [filtersActive, setFiltersActive] = useState(false)
    const facilitiesState = useSelector(state => state.facilities)
    const eventsState = useSelector(state => state.events)

    const [successMessage, setSuccessMessage] = useState(null);

    const dispatch = useDispatch()

    const toggleFilters = () => {
        setFiltersActive(!filtersActive)
    }

    useEffect(() => {
        dispatch(fetchAllFacilities())
        dispatch(fetchEvents())

        if(eventsState.createdEvent !== null) {
            setSuccessMessage('New facility created successfully!')
            dispatch(clearCreatedEvent())
            setCreatingEvent(false)
            setTimeout(() => {
                setEventPayload(null)
            }, 200);
        }
        return () => {
            
        };
    }, [dispatch, eventsState.createdEvent]);

    const [eventPayload, setEventPayload] = useState({});

    return (
        <UserLayout pageTitle="Events">

            {successMessage!==null && 
                <SuccessMessage toggleClose={()=>{setSuccessMessage(null)}} messageTitle={'Success'} messageText={successMessage}/>
            }

            <div className='p-12'>

                <div className='w-full flex items-center justify-between'>
                    <div>
                        <h4 className='font-medium text-lg mb-3 text-gray-400'>Events</h4>
                        <div className='w-2/3'>
                            <p className='text-gray-500 my-3 text-sm'>
                                Below is a list of Events at Murtala Muhammed Square. you can create a new facility by clicking on the button to the right or click on an Event below to see more details or edit the facility.
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-row gap-x-4 items-center'>
                        <button className={`flex flex-row gap-x-2 items-center bg-mms-red bg-opacity-30 rounded text-gray-700 px-4 py-3 text-sm transition duration-200  ${filtersActive ? '' : ''}`} onClick={() => {toggleFilters()}}>
                            <SlidersIcon classes="h-5 w-5 text-gray-700" />
                            Filters
                        </button>
                        <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded' onClick={()=>{setCreatingEvent(true)}}>
                            <PlusIcon className="text-white w-6 h-6" /> Create new event
                        </button>
                    </div>

                </div>

                {filtersActive && 
                <div className='w-full rounded-md bg-gray-100 bg-opacity-50 p-12 grid grid-cols-5 gap-4'>

                    <div>
                        <label className="block my-2 text-xs text-gray-700 text-opacity-50">Minimum Ticket amount</label>
                        <input type="number" placeholder='Minimum' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                    </div>

                    <div>
                        <label className="block my-2 text-xs text-gray-700 text-opacity-50">Maximum Ticket amount</label>
                        <input type="number" placeholder='Maximum' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                    </div>

                    <div>
                        <label className="block my-2 text-xs text-gray-700 text-opacity-50">Event date (from)</label>
                        <input type="date" placeholder='From' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                    </div>

                    <div>
                        <label className="block my-2 text-xs text-gray-700 text-opacity-50">Event date (to)</label>
                        <input type="date" placeholder='to' className='bg-transparent w-full text-xs rounded border border-gray-500 border-opacity-50 text-gray-500 text-opacity-50 font-medium inline-block px-3 py-2 transition duration-200 focus:outline-none' />
                    </div>

                    <div className='flex items-center mt-7'>
                        <button className='bg-mms-red text-white rounded px-4 py-3 text-xs shadow-md hover:bg-opacity-50 transition duration-200'>Apply filters</button>
                    </div>
                </div>
            }
                {eventsState.fetchingEvents === true ?
                    <div className='w-full py-12 flex items-center justify-center'>
                        <InlinePreloader />
                    </div>

                    :

                    <div className='w-full grid grid-cols-4 gap-6 relative z-30 mt-8'>

                        {eventsState?.events?.events?.length > 0 ? 
                        <>
                            {eventsState?.events?.events?.map((event, eventIndex) => (
                                <EventCard eventDetails={event} key={eventIndex} />
                            ))}
                        </> 
                        
                        : 
                            <div className='w-6/12 p-5 rounded mx-auto '>No events created yet</div>
                        }

                    </div>
                }
            </div>

            {!facilitiesState.fetchingFacilities && facilitiesState.facilities.length > 0 && 
                <SlideOutModal isOpen={creatingEvent} closeFunction={()=>{setCreatingEvent(false)}} title={`Create a new event`} subTitle={`Provide details of the upcoming event in the form below`} >
                <NewEventForm 
                    facilities={facilitiesState.facilities} 
                    eventPayload={eventPayload}
                    setEventPayload={(data)=>{setEventPayload(data)}}
                />
                </SlideOutModal>
            }
        </UserLayout>
    )
}

export default Events