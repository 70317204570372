import { FETCHING_INVITATIONS, FETCH_INVITATIONS, TEAM_ERROR } from "../types"


const initialState = {
    fetchingInvitations: false,
    invitations: null,
    teamError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case FETCHING_INVITATIONS:
        return {
            ...state,
            fetchingInvitations:action.payload
        }
        case FETCH_INVITATIONS:
        return {
            ...state,
            fetchingInvitations:false,
            invitations: action.payload
        }
        case TEAM_ERROR:
        return {
            ...state,
            fetchingInvitations:false,
            teamError:action.payload,
        }
        
        default: return state
    }

}