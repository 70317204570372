import { Switch } from '@headlessui/react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createSubscriptionPlan } from '../../../store/actions/facilitiesActions';
import { ERROR } from '../../../store/types';
import CurrencyField from '../../elements/form/CurrencyField';
import FormButton from '../../elements/form/FormButton';
import NumberField from '../../elements/form/NumberField';
import TextareaField from '../../elements/form/TextareaField';
import TextField from '../../elements/form/TextField'

const NewSubscriptionPlan = ({newPlanPayload, setNewPlanPayload, facilityId}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [published, setPublished] = useState(false);
  const facilitiesState = useSelector(state => state.facilities)
  const dispatch = useDispatch()

  const validateForm = () => {
    let errors = {}

    if(!newPlanPayload.name || newPlanPayload.name === '') {
      errors.name = true
    }

    if(!newPlanPayload.description || newPlanPayload.description === '') {
      errors.description = true
    }

    if(!newPlanPayload.duration || newPlanPayload.duration === '') {
      errors.duration = true
    }

    if(!newPlanPayload.admittanceCount || newPlanPayload.admittanceCount === '') {
      errors.admittance = true
    }

    console.log(errors)

    setValidationErrors(errors)
    return errors
  }

  const triggerCreatePlan = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please fill in all required field(s) highlighted below'
          }}}
      })
      return
    }

    dispatch(createSubscriptionPlan({...newPlanPayload, ...{facility: facilityId}}))

  }

  return (
    <>
    <div className='my-8 w-full'>
      <TextField
        inputLabel="Subscription plan name" 
        fieldId="plan-name" 
        inputType="text" 
        hasError={validationErrors.name} 
        returnFieldValue={(value)=>{setNewPlanPayload({...newPlanPayload, ...{name: value}})}}
      />
    </div>  

    <div className='my-8 w-full'>
      <TextareaField
        inputLabel="About the facility" 
        fieldId="facility-description" 
        inputType="text" 
        preloadValue=""
        hasError={validationErrors.description} 
        returnFieldValue={(value)=>{setNewPlanPayload({...newPlanPayload, ...{description: value}})}}
      />
      <label className='text-sm block w-full pt-3'>Some details about the facility - let people know what to expect</label>

    </div>  

    <div className='w-full my-8'>
      <NumberField 
        fieldId="plan-duration"
        inputLabel="Plan Duration (days)" 
        hasError={validationErrors && validationErrors.duration} 
        preloadValue={''}
        returnFieldValue={(value)=>{setNewPlanPayload({...newPlanPayload, ...{duration: value}})}}
      /> 
      <label className='text-sm block w-full pt-3'>Duration (in days) that this subscription plan will be valid</label>

    </div>

    <div className='w-full my-8'>
      <NumberField 
        fieldId="plan-admittance"
        inputLabel="Admittance" 
        hasError={validationErrors && validationErrors.admittance} 
        preloadValue={''}
        returnFieldValue={(value)=>{setNewPlanPayload({...newPlanPayload, ...{admittanceCount: value}})}}
      /> 
      <label className='text-sm block w-full pt-3'>How many people will this plan admit</label>
    </div>

    <div className='w-full my-8'>
      <CurrencyField 
          inputType="number" 
          fieldId="limit"
          inputLabel="Ticket price" 
          currencySymbol={'₦'}
          hasError={validationErrors && validationErrors.durationPrice} 
          preloadValue={''}
          returnFieldValue={(value)=>{setNewPlanPayload({...newPlanPayload, ...{durationPrice: value*100}})}}
          maximumAmount={50000}
      /> 
      <label className='text-sm block w-full pt-3'>Ignore this if the subscription plan is free</label>

    </div>

    <div className='my-8 w-full'>
      <div className='full text-right mb-6'>
        <label className="text-sm text-gray-500 font-sofia-pro w-full mb-2 mr-4">Toggle this switch to publish the facility right away</label>
        <Switch
            checked={published}
            onChange={setPublished}
            className={`${
              published ? 'bg-blue-200' : 'bg-gray-400'
            } relative inline-flex items-center h-5 rounded-full w-10`}
            >
            <span className="sr-only">Publish subscription plan</span>
            <span
                className={`transform transition ease-in-out duration-200 ${
                  published ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                } inline-block w-3 h-3 transform rounded-full`}
            />
        </Switch>
      </div>
      <FormButton buttonLabel="Create Subscription Plan" buttonAction={()=>{triggerCreatePlan()}} processing={facilitiesState.creatingPlan} />
    </div>
</>
  )
}

export default NewSubscriptionPlan