
import { CREATE_EVENT, CREATING_EVENT, EVENTS_ERROR, FETCHING_ALL_EVENTS, FETCHING_EVENT_DETAILS, FETCHING_FACILITY_EVENTS, FETCH_ALL_EVENTS, FETCH_EVENT_DETAILS, FETCH_FACILITY_EVENTS, UPDATE_EVENT, UPDATING_EVENT } from '../types'

const initialState = {
    fetchingEvents: false,
    events: [],
    creatingEvent: false,
    createdEvent: null,
    eventsError: null,
    fetchingEventDetails: false,
    eventDetails: null,
    updatingEvent: false,
    updatedEvent: null,
    fetchingFacilityEvents: false,
    facilityEvents: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case FETCHING_ALL_EVENTS:
        return {
            ...state,
            fetchingEvents:action.payload
        }
        case FETCH_ALL_EVENTS:
        return {
            ...state,
            fetchingEvents:false,
            events:action.payload,
        }
        case FETCHING_FACILITY_EVENTS:
        return {
            ...state,
            fetchingFacilityEvents:action.payload
        }
        case FETCH_FACILITY_EVENTS:
        return {
            ...state,
            fetchingFacilityEvents:false,
            facilityEvents:action.payload,
        }
        case FETCHING_EVENT_DETAILS:
        return {
            ...state,
            fetchingEventDetails:action.payload
        }
        case FETCH_EVENT_DETAILS:
        return {
            ...state,
            fetchingEventDetails:false,
            eventDetails:action.payload,
        }
        case CREATING_EVENT:
        return {
            ...state,
            creatingEvent:action.payload
        }
        case CREATE_EVENT:
        return {
            ...state,
            creatingEvent:false,
            createdEvent:action.payload,
        }
        case UPDATING_EVENT:
        return {
            ...state,
            updatingEvent:action.payload
        }
        case UPDATE_EVENT:
        return {
            ...state,
            updatingEvent:false,
            updatedEvent:action.payload,
        }
        case EVENTS_ERROR:
        return{
            ...state,
            fetchingEvents: false,
            updatingEvent: false,
            creatingEvent: false,
            eventsError: null,
        }
        
        default: return state
    }

} 