import React, { useState } from 'react'

const DateField = ({inputLabel, fieldId, disabled, hasError, preloadValue, returnFieldValue}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue || '')

    const focusField = () => {
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div 
            className={`w-full cursor-text border rounded border p-4 relative z-0 ${isFocused || fieldValue !== '' ? 'border-black' : 'border-black'} ${hasError && 'border-red-600'}`} 
            onClick={()=>{focusField()}} 
            onBlur={()=>{setIsFocused(false)}}
        >
            <label 
            className={`cursor-text bg-white text-sm z-10 absolute top-3 left-4 px-4 py-1 font-gilroy font-medium transition duration-200  
            ${isFocused || fieldValue !== '' ? '-translate-y-8' : 'translate-y-0'}  
            ${hasError ? 'text-red-600' : 'text-gray-500'}`}>
                {inputLabel}
            </label>
            <input 
                id={fieldId} 
                type="date" 
                className={`z-30 border-transparent bg-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
            />
        </div>
    )
}

export default DateField