import React from 'react'
import { Link } from 'react-router-dom'
import ArrowNarrowRight from '../../elements/icons/ArrowNarrowRight'

const FacilityCard = ({facility}) => {
    // const [openInfo, setOpenInfo] = useState(false)
    return (
        <div className='relative overflow-hidden cursor-pointer'>
            <div className='w-full z-10'>
                <div className='relative w-full' style={{
                    height: '40vh',
                    backgroundImage: `url(" ${facility.media[0]?.itemUrl} ")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none'
                    }}>
                </div>
            </div>

            <div className={`relative w-full transition duration-200 p-8`}>
                <h3 className='text-gray-600 font-medium text-lg'>{facility.name}</h3>

                <div className='py-2 border-b border-gray-200'>
                    <p className='text-gray-500 text-sm my-3'>{facility.description.length > 150 ? facility.description.substring(0, 150) + '...' : facility.description}</p>
                    <p className='text-gray-500 text-sm my-3'>Capacity: {facility.capacity > 0 ? <span className='text-gray-600'>{facility.capacity.toLocaleString()}</span> : <span className='font-semibold text-gray-600 text-2xl'>∞</span>}</p>
                    <p className='text-gray-500 text-sm my-3'>Facility type: <span className='text-xs rounded text-gray-600'>{facility.type.toLocaleString()}</span></p>
                </div>
                <Link to={`single/${facility._id}`} className='w-full mt-3 text-gray-500 font-medium transition duration-200 hover:text-mms-red flex items-center gap-x-2'>
                    See Facility <ArrowNarrowRight className="w-8 mt-1 h-6" />
                </Link>
            </div>
        </div>
    )
}

export default FacilityCard