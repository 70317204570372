import { FETCHING_ALL_TRANSACTIONS, FETCH_ALL_TRANSACTIONS, TRANSACTIONS_ERROR } from "../types"

const initialState = {
    fetchingTransactions: false,
    transactions: [],
    transactionsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case FETCHING_ALL_TRANSACTIONS:
        return {
            ...state,
            fetchingTransactions:action.payload
        }
        case FETCH_ALL_TRANSACTIONS:
        return {
            ...state,
            fetchingTransactions:false,
            transactions: action.payload
        }
        case TRANSACTIONS_ERROR:
        return {
            ...state,
            fetchingTransactions: false,
            transactionsError:action.payload,
        }
        
        default: return state
    }

}