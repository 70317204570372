import { CREATE_TICKET, CREATING_TICKET, FETCHING_ALL_TICKETS, FETCHING_EVENT_TICKETS, FETCH_ALL_TICKETS, FETCH_EVENT_TICKETS, TICKETS_ERROR, UPDATE_TICKET, UPDATING_TICKET } from "../types"

const initialState = {
    fetchingTickets: false,
    tickets: [],
    creatingTicket: false,
    createdTicket: null,
    updatingTicket: false,
    updatedTicket: null,
    ticketsError: null,
    eventTickets: [],
    fetchingEventTickets: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case FETCHING_ALL_TICKETS:
        return {
            ...state,
            fetchingTickets:action.payload
        }
        case FETCH_ALL_TICKETS:
        return {
            ...state,
            fetchingTickets:false,
            tickets: action.payload
        }
        case CREATING_TICKET:
        return {
            ...state,
            creatingTicket:action.payload
        }
        case CREATE_TICKET:
        return {
            ...state,
            creatingTicket:false,
            createdTicket:action.payload,
        }
        case UPDATING_TICKET:
        return {
            ...state,
            updatingTicket:action.payload
        }
        case UPDATE_TICKET:
        return {
            ...state,
            updatingTicket:false,
            updatedTicket:action.payload,
        }
        case FETCHING_EVENT_TICKETS:
        return {
            ...state,
            fetchingEventTickets:action.payload
        }
        case FETCH_EVENT_TICKETS:
        return {
            ...state,
            fetchingEventTickets:false,
            eventTickets:action.payload,
        }
        case TICKETS_ERROR:
        return {
            ...state,
            creatingTicket:false,
            fetchingTickets: false,
            ticketsError:action.payload,
        }
        
        default: return state
    }

}