import React, { useState } from 'react'
import EyeIcon from '../icons/EyeIcon'
import EyeOffIcon from '../icons/EyeOffIcon'

const TextField = ({inputLabel, fieldId, preloadValue, inputType, hasError, returnFieldValue}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue || '')
    const [ hiddenInput, setHiddenInput ] = useState(true)

    const focusField = () => {
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div 
            className={`w-full cursor-text border rounded border p-4 relative z-0 ${isFocused || fieldValue !== '' ? 'border-black' : 'border-black'} ${hasError && 'border-red-600'}`} 
            onClick={()=>{focusField()}} 
            onBlur={()=>{setIsFocused(false)}}
        >
            <label 
            className={`cursor-text bg-white text-sm z-10 absolute top-3 left-4 px-4 py-1 font-gilroy font-medium transition duration-200  
            ${isFocused || fieldValue !== '' ? '-translate-y-8' : 'translate-y-0'}  
            ${hasError ? 'text-red-600' : 'text-gray-500'}`}>
                {inputLabel}
            </label>
            <input 
                id={fieldId} 
                type={hiddenInput && inputType === 'password' ? inputType : "text"} 
                className={`z-30 border-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                value={fieldValue}
                onChange={(e)=>{setValue(e.target.value)}}/>
            {inputType === 'password' && 
            <button className='absolute z-40 right-4 top-4' onClick={()=>{setHiddenInput(!hiddenInput)}}>
                {hiddenInput ?
                <EyeIcon />
                :
                <EyeOffIcon />}
            </button>
            }
        </div>
    )
}

export default TextField