import React from 'react'
import FormButton from '../../components/elements/form/FormButton';
import TextField from "../../components/elements/form/TextField";
import Logo from "../../assets/img/icon.png"
import { Link } from 'react-router-dom';

const ResetRequest = () => {
  return (
    <div className='w-full min-h-screen flex items-center justify-center bg-blue-100 bg-opacity-50'>
    <div className='w-1/3 px-12'>
      <img src={Logo} alt="" className='w-16 ml-auto mr-auto mb-8' />
      <div className='bg-white rounded-md p-8 lg:p-12 shadow-xl shadow-blue-600/10'>
        <div className='w-10/12 ml-auto mr-auto text-center'>
          <h3 className='text-2xl mb-4'>Forgot your password?</h3>
          <p className='text-gray-400'>Please provide your registered email address and we'll send you a link to create a new one</p>
        </div>
        <div className='my-8 w-full'>
          <TextField
            inputLabel="Email Address" 
            fieldId="email" 
            inputType="email" 
            hasError={false} 
            returnFieldValue={()=>{}}
          />
        </div>
        <div className='w-full text-center'>
          <p className='text-gray-400 text-sm'>Want to try again? <Link className='text-blue-500 font-medium' to='/'>Click here</Link> to go back to login page</p>
        </div>
        <div className='my-8 w-full'>
          <FormButton buttonLabel="Request password reset" buttonAction={()=>{}} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default ResetRequest