
import { CREATE_FACILITY, CREATE_SUBSCRIPTION_PLAN, CREATING_FACILITY, CREATING_SUBSCRIPTION_PLAN, FACILITIES_ERROR, FETCHING_ALL_FACILITIES, FETCHING_FACILITY_DETAILS, FETCHING_FACILITY_PLANS, FETCH_ALL_FACILITIES, FETCH_FACILITY_DETAILS, FETCH_FACILITY_PLANS, UPDATE_SUBSCRIPTION_PLAN, UPDATING_SUBSCRIPTION_PLAN } from '../types'

const initialState = {
    fetchingFacilities: false,
    facilities: [],
    creatingFacility: false,
    createdFacility: null,
    creatingPlan: false,
    createdPlan: null,
    facilitiesError: null,
    fetchingFacilityDetails: false,
    facilityDetails: null,
    fetchingFacilityPlans: false,
    facilityPlans: null,
    creatingSubscriptionPlan: false,
    createdSubscriptionPlan: null,
    updatingSubscriptionPlan: false,
    updatedSubscriptionPlan: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case FETCHING_ALL_FACILITIES:
        return {
            ...state,
            fetchingFacilities:action.payload
        }
        case FETCH_ALL_FACILITIES:
        return {
            ...state,
            fetchingFacilities:false,
            facilities:action.payload,
        }
        case FETCHING_FACILITY_DETAILS:
        return {
            ...state,
            fetchingFacilityDetails:action.payload
        }
        case FETCH_FACILITY_DETAILS:
        return {
            ...state,
            fetchingFacilityDetails:false,
            facilityDetails:action.payload,
        }
        case FETCHING_FACILITY_PLANS:
        return {
            ...state,
            fetchingFacilityPlans:action.payload
        }
        case FETCH_FACILITY_PLANS:
        return {
            ...state,
            fetchingFacilityPlans:false,
            facilityPlans:action.payload,
        }
        case CREATING_FACILITY:
        return {
            ...state,
            creatingFacility:action.payload
        }
        case CREATE_FACILITY:
        return {
            ...state,
            creatingFacility:false,
            createdFacility:action.payload,
        }
        case CREATING_SUBSCRIPTION_PLAN:
        return {
            ...state,
            creatingSubscriptionPlan:action.payload
        }
        case CREATE_SUBSCRIPTION_PLAN:
        return {
            ...state,
            creatingSubscriptionPlan:false,
            createdSubscriptionPlan:action.payload,
        }
        case UPDATING_SUBSCRIPTION_PLAN:
        return {
            ...state,
            updatingSubscriptionPlan:action.payload
        }
        case UPDATE_SUBSCRIPTION_PLAN:
        return {
            ...state,
            updatingSubscriptionPlan:false,
            updatedSubscriptionPlan:action.payload,
        }
        case FACILITIES_ERROR:
            return{
            ...state,
            updatingSubscriptionPlan:false,
            updatedSubscriptionPlan:null,
            fetchingFacilities: false,
            creatingFacility: false,
            fetchingFacilityDetails: false,
            fetchingFacilityPlans: false,
            creatingSubscriptionPlan: false,
            facilitiesError: action.payload,
        }
        
        default: return state
    }

} 