import React from 'react'
import { Outlet } from 'react-router-dom'
import UserLayout from '../../components/Layout/UserLayout'

const Settings = () => {
    return (
        <UserLayout pageTitle="Settings">
            <Outlet />
        </UserLayout>
    )
}

export default Settings
