import React from 'react'
import UserMenu from './UserMenu'
import SearchIcon from '../elements/icons/SearchIcon'

const UserHeader = ({pageTitle}) => {
  return (
    <div className='flex items-center justify-between w-full'>
      <div>
        <h3 className='text-xl font-medium text-gray-600'>{pageTitle}</h3>
      </div>
      <div className='mr-12 flex flex-row items-center gap-x-4 w-6/12'>
        <div className="relative rounded-lg shadow-sm border border-gray-200 py-2 px-4 bg-white w-full">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm mt-1">
                    <SearchIcon className="w-6 h-6 text-gray-400" />
                </span>
            </div>
            <input type="text" className="block w-full focus:border-transparent focus:outline-none pl-7 font-outfit placeholder:font-outfit" placeholder="Search for events, users or facilities" />
        </div>
        <UserMenu logOut={()=>{}} />
      </div>
    </div>
  )
}

export default UserHeader