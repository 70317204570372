import axios from "axios"
import { applySort, authHeader, parseFilters } from "../../utils/utils"
import { FETCHING_ALL_USERS, FETCHING_USER_DETAILS, FETCH_ALL_USERS, FETCH_USER_DETAILS, USERS_ERROR } from "../types"

export const fetchAllUsers = (reqPagination, filters, sort) => async dispatch => {    
    let pagination = reqPagination
    if(!reqPagination) {
        pagination = {
            page: 1,
            perPage: 100000
        }
    }
    try{
        let appliedFilters = null
        
        if (filters && filters!==null && filters.length > 0) {
            appliedFilters = parseFilters(filters)
        }

        const headers = authHeader()
        dispatch( {
            type: FETCHING_ALL_USERS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/all?perPage=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })
        dispatch( {
            type: FETCH_ALL_USERS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: USERS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const fetchUserDetails = (userId) => async dispatch => {    
    try{
        const headers = authHeader()
        dispatch( {
            type: FETCHING_USER_DETAILS,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/details/${userId}`, { headers })
        dispatch( {
            type: FETCH_USER_DETAILS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch( {
            type: USERS_ERROR,
            error,
            // payload: error.response.data,
        })
    }
}

export const clearUserDetails = () => async dispatch => {
    dispatch( {
        type: FETCH_USER_DETAILS,
        payload: null
    })
}