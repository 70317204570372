// export const baseUrl = 'http://localhost:5550'
export const baseUrl = process.env.REACT_APP_API_URL

export const convertCamelCase = (camelCaseText) => {
    const text = camelCaseText;
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult
}

export const tableHeadersFields = (sampleObject) => {
    if(!sampleObject) {
        return []
    }
    const headers = []
    const fields = []
    Object.keys(sampleObject).forEach((key, index)=>{
        let columnDataType = 'text'
        let forPopover = false
        let columnDisplayName = convertCamelCase(key)
        let sortable = false
        let column = key

        headers.push({
            column,
            columnDisplayName,
            data: sampleObject[key],
            sortable,
            forPopover,
            columnDataType
        })

        let fieldSelected = true

        if(index > 10) {
            fieldSelected = false
        }
        fields.push({
            name: columnDisplayName,
            selected: fieldSelected
        })
    });
    return {headers, fields}
}

export const authHeader = () => {
    let accessToken = ''
    if (typeof window !== 'undefined') {
        accessToken = localStorage.getItem('accessToken');
    }
    return { Authorization: 'Bearer ' + accessToken };
}

export const userDetails = () => {

    let user = null
    if (typeof window !== 'undefined') {
        // Perform localStorage action
        // const item = localStorage.getItem('key')
        user = JSON.parse(localStorage.getItem('user'));
    }
    return user
}

export const isOdd = (num) => { 
  return num % 2;
}

export const isValidObject = (obj) => {
    return obj && obj !== 'null' && obj !== 'undefined';
}

export const getPreselectedIndex = (value, optionsArray, valueLabel) =>{
    let preSelected = null 

    optionsArray.map((option, optionIndex) => {
        if(option[valueLabel] === value) {
            preSelected = optionIndex
        }
        return option
    })

    return preSelected
}

export const toTimeStamp = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()
    const time = dateObj.getUTCHours() + ':' + dateObj.getUTCMinutes() + ':' + dateObj.getUTCSeconds()
    const timeStamp = `${month}/${day}/${year} @ ${time}`
    return timeStamp
}

export const sortArrayByObjectKey = (array, key) => {
    if(!array || !key) {
        return
    }

    function SortArray(x, y){
        return x[key].localeCompare(y[key]);
    }
    var s = array.sort(SortArray);

    return s
}

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
} 
  
export const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-');
}

export const applySort = (sortObject) => {
    if(!sortObject) {
        return ''
    }
    let sortString = ''
    if (sortObject.sortBy && sortObject.sortBy !== '') {
        sortString = `sort=${sortObject.sortOrder === 'DESC' ? '-' : ''}${sortObject.sortBy}`
    }
    return sortString
}

export const parseFilters = (filtersArray, action) => {

    if(!filtersArray || filtersArray.length === 0) {
        return null
    }

    const cleanedArray = filtersArray.filter((item, itemIndex) => { 
        return item.term !== ''
    })

    let filtersString = cleanedArray.map((filterObject) => {
        let string = 'or='
        if(action && action === 'filter') {
            string = 'and='
        }
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(filterObject)) {
            string += `${value}||`
        }
        return string.slice(0, -2)
    })

    if (filtersString.length > 0) {
        return filtersString.join('&').toString()
    } else {
        return ''
    }

} 

export const format12hrTime = (date) =>  {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime.toUpperCase();
}

export const timeStamp = (rawTimeStamp) => {
    const today = new Date()
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let formattedDate = new Date(rawTimeStamp).toDateString()
    // let formattedTime = rawTimeStamp.toTimeS
    if(today.toDateString() === new Date(rawTimeStamp).toDateString()) {
        formattedDate = 'Today'
    }

    if (yesterday.toDateString() === new Date(rawTimeStamp).toDateString()) {
        formattedDate = 'Yesterday'
    }

    return {
        date: formattedDate,
        time: format12hrTime(new Date(rawTimeStamp))
    }
}

export const eventTypes = [
    {
        label: 'Regular',
        value:'REGULAR'
    },
    {
        label: 'Versus',
        value:'VERSUS'
    },
]

export const facilityTypes = [
    {
        label: 'Outdoor Facility',
        value:'OUTDOOR'
    },
    {
        label: 'Indoor Facility',
        value:'INDOOR'
    },
]

// test env/