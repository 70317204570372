import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import UserIcon from '../elements/icons/UserIcon'

const SettingsLayout = ({children, pageTitle}) => {
    const userDetails = {
        name: "John Doe",
        userCode: "763822595819",
        phone: "+2348012345678",
        email: "immanuel.o.onum@gmail.com"
    }

    return (
        <Fragment>
            {/* <p>Settings Layout - {pageTitle}</p> */}
            {/* <main>{children}</main> */}
            <div className='w-10/12 ml-auto mr-auto flex flex-row justify-center pt-12'>
                <div className='w-11/12 margin-auto flex flex-row gap-x-8'>
                    <div className='w-2/12 bg-white p-4 border rounded-lg'>
                        <div className='w-full flex items-center justify-center'>
                            <div className="flex w-16 h-16 flex-row justify-center items-center rounded-xl bg-gray-100 text-white border-4 border-secondary shadow-md inline-block">
                                <UserIcon className="w-8 h-8" />
                            </div>
                        </div>
                        <div className='w-full text-center'>
                            <p className="text-sm mt-3 font-bold">John Doe</p>
                            <p className="text-xs font-medium mt-2">Super Administrator</p>
                        </div>
                        
                        {/* <div className='bg-gradient-to-r from-black via-white to-black h-px w-full my-4' /> */}

                        <div className='mt-6'>
                            <NavLink to="/admin/settings/user-profile" className='w-full p-2 rounded text-xs block'>
                                User Profile
                            </NavLink>
                            <NavLink to="/admin/settings/team" className='w-full p-2 rounded text-xs block'>
                                Team
                            </NavLink>
                            {/* <NavLink activeClassName="text-accent" to="/admin/settings/notifications" className='w-full p-2 rounded text-xs block'>
                                Notifications
                            </NavLink> */}
                            <NavLink to="/admin/settings/security" className='w-full p-2 rounded text-xs block'>
                                Security
                            </NavLink>
                        </div>
                    </div>
                    <div className='w-10/12'>
                        <main>{children(userDetails)}</main>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SettingsLayout
