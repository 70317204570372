import React, {useState} from 'react'
// import LockIcon from '../../../components/elements/icons/LockIcon'
import SettingsLayout from '../../components/Layout/SettingsLayout'
import { Switch } from '@headlessui/react'
import ModalLayout from '../../components/Layout/ModalLayout'
import KeyIcon from '../../components/elements/icons/KeyIcon'
import LockIcon from '../../components/elements/icons/LockIcon'

const Security = () => {
    const [passwordResetModal, setPasswordResetModal] = useState(false)
    const [twoFaModal, setTwoFaModal] = useState(false)
    const [twoFaActive, setTwoFaActive] = useState(false)

    const changePassword = () => {
        console.log('password changed :)')
    }

    const activate2Fa = () => {
        console.log('2FA activated :)')
    }

    // const deactivate2Fa = () => {
    //     console.log('2FA activated :)')
    // }

    return (
        <>
        <SettingsLayout pageTitle="User Profile">
            {userDetails => (
                <>
                    <h3 className="text-accent text-md font-medium">Security Settings</h3>
                    
                    <div className='flex flex-row gap-x-8 my-8'>
                        <div className="bg-white p-4 rounded-lg flex flex-row gap-x-3 shadow-md items-center w-1/3 transition duration-200 hover:bg-opacity-20 cursor-pointer hover:shadow-lg" onClick={()=>{setPasswordResetModal(true)}}>
                            <div className="p-3 rounded-lg text-secondary bg-accent shadow-md">
                                <KeyIcon classes="h-6 w-6" />
                            </div>
                            <div>
                                <p className="text-primary text-sm font-medium">Account Passord</p>
                                <p className="text-xs mt-2 text-opacity-40">Manage your account password</p>
                            </div>
                        </div>

                        <div className="bg-white p-4 rounded-lg flex flex-row gap-x-3 shadow-md items-center w-1/3 transition duration-200 hover:bg-opacity-20 cursor-pointer hover:shadow-lg" onClick={()=>{setTwoFaModal(true)}}>
                            <div className="p-3 rounded-lg text-secondary bg-accent shadow-md">
                                <LockIcon className="h-6 w-6" />
                            </div>
                            <div>
                                <p className="text-primary text-sm font-medium">Two-Factor Authentication</p>
                                <p className="text-xs mt-2 text-opacity-40">Manage two-factor authentication settings for your account</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </SettingsLayout>

        <ModalLayout 
            isOpen={passwordResetModal} 
            closeModal={()=>{setPasswordResetModal(false)}} 
            actionFunction={()=>{changePassword()}} 
            actionFunctionLabel='Change Password' 
            dialogTitle='Change Your Account Password'
            maxWidthClass='max-w-md'
        >
            <div>
                <label className="mt-6 mb-3 text-xs text-primary block">Current Password</label>
                <input type="password" placeholder="Provide your current password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
            </div>
            <div className='mt-10'>
                <label className="mt-6 mb-3 text-xs text-primary block">New Password</label>
                <input type="password" placeholder="Choose a new password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
            </div>
            <div>
                <label className="mt-6 mb-3 text-xs text-primary block">Confirm New Password</label>
                <input type="password" placeholder="Re-type new password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
            </div>
        </ModalLayout>

        <ModalLayout 
        // dialogTitle, dialogIntro, isOpen, closeModal, maxWidthClass
            isOpen={twoFaModal} 
            closeModal={()=>{setTwoFaModal(false)}} 
            actionFunction={()=>{activate2Fa()}} 
            actionFunctionLabel='Activate two-factor authentication' 
            dialogTitle='Activate/Deactivate Two-Factor Authentication'
            maxWidthClass='max-w-md'
        >
             <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                 <div className='w-4/5'>
                    <p className="text-sm font-sofia-pro">Activate Two-factor Authentication</p>
                    <p className='text-gray-500 text-xs mt-2'>
                        Two-factor authentication further protects access your account by sending an OTP to your registered phone number and email address everytime you make an attempt to log in to your account
                    </p>
                 </div>
                <Switch
                    checked={twoFaActive}
                    onChange={setTwoFaActive}
                    className={`${
                        twoFaActive ? 'bg-gray-300' : 'bg-gray-400'
                    } relative inline-flex items-center h-5 rounded-full w-10`}
                    >
                    <span className="sr-only">Activate 2FA</span>
                    <span
                        className={`transform transition ease-in-out duration-200 ${
                            twoFaActive ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                        } inline-block w-3 h-3 transform rounded-full`}
                    />
                </Switch>
            </div>
        </ModalLayout>
        </>
    )
}

export default Security
