import React from 'react'
import Verve from '../../assets/img/verve.svg'
import { Link } from 'react-router-dom'
import MasterCardLogoIcon from '../elements/icons/MasterCardLogoIcon'
import VisaCardLogoIcon from '../elements/icons/VisaCardLogoIcon'

const TransactionDetails = ({transaction}) => {
  return (
    <div className='w-full'>
        <div className='flex flex-row justify-between items-start'>
            <div>
                <label className='w-full block text-sm font-light'>TRANSACTION REFERENCE</label>
                <p>{transaction.transactionReference}</p>
            </div>
            <div>
                {transaction.status === 'SUCCESSFUL' && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-green-300 bg-opacity-30 text-green-500 border border-green-500`}>{transaction.status}</p>}
                {transaction.status === 'PENDING' && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-gray-300 bg-opacity-30 text-gray-500 border border-gray-500`}>{transaction.status}</p>}
                {transaction.status === 'FAILED' && <p className={`font-medium text-xs px-2 py-1 rounded inline-block bg-red-100 bg-opacity-30 text-red-500  border border-red-500`}>{transaction.status}</p>}
            </div>
        </div>
        <div className='flex flex-row justify-between items-start mt-6'>
            <div>
                <label className='w-full block text-sm font-light'>DATE &amp; TIME</label>
                <p className='font-medium text-gray-500 text-sm'>{new Date(transaction.createdAt).toDateString()} - {new Date(transaction.createdAt).toTimeString().split(" ")[0]}</p>
            </div>
            <div>
                {/* <label className='w-full block text-sm font-thin'>AMOUNT</label> */}
                <div className='flex'>
                    <span className='text-gray-500 text-md mt-1'>₦</span>
                    <p className='text-2xl font-medium text-gray-600'>{(transaction.amount/100).toLocaleString()}</p>
                </div>
            </div>
        </div>
        {transaction.processorData && transaction.processorData.card && <div className='flex flex-row justify-between items-start mt-6'>
            <div>
                <label className='w-full block text-sm font-light mb-3'>PAID VIA</label>
                <div className='flex gap-x-4'>
                    <div className='w-12 relative'>
                        {transaction.processorData.card.type === 'MASTERCARD' && <MasterCardLogoIcon className={`w-[60px] h-[60px]`} />}
                        {transaction.processorData.card.type === 'VISA' && <VisaCardLogoIcon className={`w-[60px] h-[60px]`} /> }
                        {transaction.processorData.card.type === 'VERVE' && <img
                            src={Verve}
                            className='w-full' 
                            alt='' 
                        />}
                    </div>

                    <p className='font-medium text-black'>**** **** **** {transaction.processorData.card.last_4digits}</p>
                    <p className='font-light text-gray-500'>{transaction.processorData.card.expiry}</p>
                </div>
            </div>
            <div>
                {/* <div className='flex'>
                    <span className='text-gray-500 text-md mt-1'>₦</span>
                    <p className='text-2xl font-medium text-gray-600'>{(transaction.amount/100).toLocaleString()}</p>
                </div> */}
            </div>
        </div>}

        {transaction.paymentFor && <div className='flex flex-row justify-between items-start mt-6'>
            <div>
                <label className='w-full block text-sm font-light'>PAID FOR</label>
                <p className='font-medium text-black'><span className='capitalize'>{transaction.paymentFor.toLowerCase()}</span> for {transaction.paymentItem.name}</p>
                {transaction.paymentFor === 'TICKET' && <Link to={`/events/${transaction.paymentFor._id}`}>
                    <p className='transition duration-200 text-sm font-medium text-gray-600 hover:text-blue-400'>See event details</p>
                </Link>}
                {/* {transaction.paymentFor === 'SUBSCRIPTION' && <Link href={``}>
                    <a className='text-2xl font-medium text-gray-600'>{(transaction.amount/100).toLocaleString()}</a>
                </Link>} */}
            </div>
            <div>
                <div className='flex'>
                    {/* <span className='text-gray-500 text-md mt-1'>₦</span> */}
                    
                </div>
            </div>
        </div>}
    </div>
  )
}

export default TransactionDetails