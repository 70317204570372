import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotAllowedIcon from '../../../components/elements/icons/NotAllowedIcon';
import PencilSquareIcon from '../../../components/elements/icons/PencilSquareIcon';
import PlusIcon from '../../../components/elements/icons/PlusIcon';
import InlinePreloader from '../../../components/elements/InlinePreloader';
import MediaSlider from '../../../components/elements/MediaSlider';
import SuccessMessage from '../../../components/elements/SuccessMessage';
import SlideOutModal from '../../../components/Layout/SlideOutModal';
import UserLayout from '../../../components/Layout/UserLayout'
import EditEventForm from '../../../components/partials/events/EditEventForm';
import NewTicketForm from '../../../components/partials/tickets/NewTicketForm';
import { clearUpdatedEvent, fetchSingleEvent, updateEventDetails, fetchEvents } from '../../../store/actions/eventsActions';
import { fetchAllFacilities } from '../../../store/actions/facilitiesActions';
import { fetchTicketsForEvent } from '../../../store/actions/ticketsActions';
import { timeStamp } from '../../../utils/utils';

const Event = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const eventsState = useSelector(state => state.events)
  const facilitiesState = useSelector(state => state.facilities)
  const ticketsState = useSelector(state => state.tickets)
  const [eventUpdatePayload, setEventUpdatePayload] = useState({});
  const [editingEvent, setEditingEvent] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [ticketPayload, setTicketPayload] = useState({
    event: id
  });
  const [creatingTicket, setCreatingTicket] = useState(false);

  useEffect(() => {
    dispatch(fetchSingleEvent(id))
    dispatch(fetchEvents())
    dispatch(fetchAllFacilities())
    dispatch(fetchTicketsForEvent({
      page: 1,
      perPage: 5
    }, id))

    if(eventsState.updatedEvent !== null) {
      setSuccessMessage('Event updated successfully!')
      dispatch(clearUpdatedEvent())
      setEventUpdatePayload(null)
      dispatch(fetchSingleEvent(id))
    }

    return () => {
      
    };
  }, [dispatch, eventsState.updatedEvent, id]);

  const updateEvent = () => {
    console.log('2', eventUpdatePayload)
    if(!eventUpdatePayload || Object.keys(eventUpdatePayload).length === 0) {
      return
    }
    dispatch(updateEventDetails({...eventUpdatePayload, ...{eventId: id}}))
  }

  const changePublishStatus = () => {
    console.log('1')
    setEventUpdatePayload({published: !eventsState.eventDetails.published})
    setTimeout(() => {
      updateEvent()
    }, 200);
  }

  const changeTicketSaleStatus = () => {
    setEventUpdatePayload({sellingTickets: !eventsState.eventDetails.sellingTickets})
    updateEvent()
  }

  const cancelEvent = () => {
    setEventUpdatePayload({
      cancelled: true,
      sellingTickets: false,
      published: false
    })
    updateEvent()
  }

  const closeNewTicket = () => {
    setCreatingTicket(false)
    setTimeout(() => {
      setTicketPayload({})
    }, 100);
  }

  return (
    <UserLayout pageTitle="Dashboard">
      {successMessage!==null && 
        <SuccessMessage toggleClose={()=>{setSuccessMessage(null)}} messageTitle={'Success'} messageText={successMessage}/>
      }

      {eventsState.fetchingEventDetails === true ?
        <div className='w-full py-12 flex items-center justify-center'>
            {/* {eventsState.fetchingEvents.toString()} */}
            <InlinePreloader />
        </div>

      :

        <div className=''>
          <div className='w-full flex flex-row gap-x-8'>
            <div className='w-9/12 pb-6 px-12 mt-12'>
                <div className='border-b border-gray-200 pb-6 flex justify-between'>
                  <div className='w-6/12'>
                    <h3 className='font-medium text-2xl text-gray-400'>{eventsState?.eventDetails?.name} {(eventsState?.eventDetails?.cancelled === true) && <span className='ml-3 text-sm border rounded border-gray-400 text-gray-300 p-2'>Event Cancelled</span>}</h3>
                  </div>

                  <div className='w-7/12 flex flex-row-reverse items-center gap-x-6'>
                    
                  {(!eventsState.eventDetails?.cancelled || eventsState.eventDetails?.cancelled === false) &&  <button className='px-4 py-3 bg-mms-red text-white flex items-center gap-x-2 text-sm rounded' onClick={()=>{setEditingEvent(true)}}>
                          <PencilSquareIcon className="text-white w-6 h-6" /> Edit Event Details
                    </button>}
                    <div className='pl-4'>
                      <label className="text-sm text-gray-500 font-sofia-pro block w-full mb-2">Selling Tickets</label>
                      <Switch
                          checked={eventsState?.eventDetails?.sellingTickets === true}
                          onChange={changeTicketSaleStatus}
                          className={`${
                            eventsState?.eventDetails?.sellingTickets === true ? 'bg-blue-200' : 'bg-gray-400'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          <span className="sr-only">Enable on contact page</span>
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                                  eventsState?.eventDetails?.sellingTickets === true ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                              } inline-block w-3 h-3 transform rounded-full`}
                          />
                      </Switch>
                    </div>
                    <div className='pr-4'>
                      <label className="text-sm text-gray-500 font-sofia-pro block w-full mb-2">Published</label>
                      <Switch
                          checked={eventsState?.eventDetails?.published === true}
                          onChange={()=>changePublishStatus()}
                          className={`${
                            eventsState?.eventDetails?.published === true ? 'bg-blue-200' : 'bg-gray-400'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          <span className="sr-only">Enable on contact page</span>
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                                  eventsState?.eventDetails?.published === true ? 'translate-x-6 bg-mms-red' : 'translate-x-1 bg-gray-600'
                              } inline-block w-3 h-3 transform rounded-full`}
                          />
                      </Switch>
                    </div>
                    {(!eventsState.eventDetails?.cancelled || eventsState.eventDetails?.cancelled === false) && <button className='px-4 py-3 flex items-center gap-x-2 text-sm rounded text-red-400' onClick={()=>{cancelEvent()}}>
                        <NotAllowedIcon className="text-red-400 w-6 h-6" /> Cancel Event
                    </button>}
                  </div>
                </div>
                
                <div className='w-8/12 mx-auto'>
                <div className='w-full xl:flex items-end justify-between gap-x-12'>
                <div className='w-full mr-auto z-20 my-8'>
                  {/* <h4 className='font-medium text-lg mb-3 text-gray-400 uppercase'>{eventDetails.name}</h4> */}
                  <label className='text-gray-500 mb-3 block text-sm'>Event description</label>
                  <p className='text-gray-800 mt-4'>{eventsState?.eventDetails?.description} </p>

                  <div className='px-8 mt-4 pt-3'>
                    {eventsState?.eventDetails?.eventType === 'VERSUS' && 
                    <>
                      <div className='w-full xl:flex gap-x-6 items-start'>
                          <div className='w-full lg-w-6/12 xl:w-full flex items-center gap-x-4 mb-12'>
                            <div className='relative w-2/5 animate__animated animate__fadeIn'>
                            {eventsState?.eventDetails.homeSide.logoUrl && eventsState?.eventDetails.homeSide.logoUrl !== '' ? 
                                <div className='rounded-md border border-gray-300 w-[65px] h-[65px]' style={{backgroundImage: `url(${ eventsState?.eventDetails.homeSide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                </div>
                                :
                                <div className='w-[60px] h-[60px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                            }
                            </div>
                            <p className='text-sm font-light text-gray-500'>VS</p>
                            <div className='relative w-2/5 animate__animated animate__fadeIn'>
                            {eventsState.eventDetails.awaySide.logoUrl && eventsState?.eventDetails.awaySide.logoUrl !== '' ? 
                                <div className='rounded-md border border-gray-300 w-[65px] h-[65px]' style={{backgroundImage: `url(${ eventsState?.eventDetails.homeSide.logoUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                </div>
                                :
                                <div className='w-[60px] h-[60px] bg-gray-300 mx-auto bg-opacity-50 rounded-md border border-gray-300'></div>
                            }
                            </div>
                          </div>

                          <div className='w-full'>
                              <p className='font-medium text-xl'>{eventsState?.eventDetails?.homeSide.name} </p>
                              <p className='text-gray-500 font-light'>Vs </p>
                              <p className='font-medium text-xl'>{eventsState?.eventDetails?.awaySide.name} </p>
                              
                              <p className='mt-3 text-md'>Date: {new Date(eventsState?.eventDetails?.eventDate).toDateString()}</p>
                              <p className='mt-3 text-md'>Time: {eventsState?.eventDetails?.eventTime}</p>
                              
                          </div>
                      </div>
                      </>}

                  </div>

                  </div>

                </div>
                
                <div className='py-12'>
                  <MediaSlider slides={eventsState?.eventDetails?.media} />
                </div>
              </div>

            </div>

            <div className='w-3/12 bg-gray-100 h-screen p-10 fixed overflow-y-scroll right-0'>
              <div className='py-6 border-b border-gray-400'>
                <h3 className='font-medium text-lg text-gray-700'>Event Venue</h3>
                <p className='text-sm mt-2 text-gray-500 mb-6'>This is your revenue performance for the selected time period across all revenue streams. <br />Use the switch below to toggle the time period and the dropdown to select revenue source</p>

                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Facility name</label>
                <p className='text-gray-800 mt-2 mb-8'>{eventsState?.eventDetails?.venue?.name} </p>
                
                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Capacity</label>
                <p className='text-gray-800 mt-2 mb-8'>{eventsState?.eventDetails?.venue?.capacity?.toLocaleString()} </p>

                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Type</label>
                <p className='text-gray-800 mt-2 mb-8 capitalize'>{eventsState?.eventDetails?.venue?.type?.toLowerCase()} </p>
              </div>
              <div className='py-6 border-b border-gray-400 pb-12'>
                {ticketsState?.fetchingEventTickets ? 
                  <div className='w-full py-12 flex items-center justify-center'>
                    <InlinePreloader />
                  </div>
                  :
                  <>
                  {/* {ticketsState?.eventTickets?.data?.total} */}
                    <h3 className='font-courier-prime text-4xl text-black'>{ticketsState?.eventTickets?.total?.toLocaleString()}<span className='ml-2 text-sm'>tickets sold for this event</span></h3>

                    {ticketsState?.eventTickets?.tickets?.length > 0 && 
                    <>
                      <div className='flex items-center justify-between items-center mt-5'>
                        <p className='font-medium mt-5 text-gray-500'>Recent Ticket Sales</p>
                        {eventsState?.eventDetails?.sellingTickets === true && <button onClick={()=>{setCreatingTicket(true)}} className='px-2 py-1 bg-mms-red text-white flex items-center gap-x-1 text-sm rounded'>
                          <PlusIcon className="text-white w-6 h-6" /> New ticket
                        </button>}
                      </div>
                      <div className='flex items-center gap-x-4 p-2 border-b bg-gray-200 mt-4'>
                        <div className='w-4/12'>
                          <p className='text-xs font-medium'>Ticket code</p>
                        </div>
                        <div className='w-6/12'>
                          <p className='text-xs font-gray-500'>Date Purchased</p>
                        </div>
                        <div className='w-2/12'>
                          <p className='text-xs font-gray-500'>Status</p>
                        </div>
                      </div>
                      {ticketsState?.eventTickets?.tickets?.map((ticket, ticketIndex)=>(
                        <div className='flex items-center gap-x-4 py-4 px-2 border-b border-gray-300' key={ticketIndex}>
                          <div className='w-4/12'>
                            <p className='text-sm font-medium'>{ticket.ticketCode}</p>
                          </div>
                          <div className='w-6/12'>
                            <p className='text-xs font-gray-500'>{timeStamp(ticket.createdAt).date} @ {timeStamp(ticket.createdAt).time}</p>
                          </div>
                          <div className='w-2/12'>
                            <p className={`p-1 block w-full rounded text-xs font-gray-500 capitalize border ${ticket.ticketStatus === 'ADMITTED' ? 'bg-green-100 text-green-700 border-green-700' : 'bg-yellow-100 text-yellow-700 border-yellow-700'} `}>{ticket.ticketStatus.toLowerCase()}</p>
                          </div>
                        </div>
                      ))}
                      </>
                    }
                  </>
                  }
                {/* <p className='text-sm mt-2 text-gray-500 mb-6'>This is your revenue performance for the selected time period across all revenue streams. <br />Use the switch below to toggle the time period and the dropdown to select revenue source</p>

                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Facility name</label>
                <p className='text-gray-800 mt-2 mb-8'>{eventsState?.eventDetails?.venue?.name} </p>
                
                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Capacity</label>
                <p className='text-gray-800 mt-2 mb-8'>{eventsState?.eventDetails?.venue?.capacity} </p>

                <label className='text-gray-500 mt-8 mb-3 block text-sm'>Type</label>
                <p className='text-gray-800 mt-2 mb-8 capitalize'>{eventsState?.eventDetails?.venue?.type?.toLowerCase()} </p> */}
              </div>
            </div>
          </div>

        </div>

    }
    {!eventsState.fetchingEventDetails && eventsState?.eventDetails &&
      <SlideOutModal isOpen={editingEvent} closeFunction={()=>{setEditingEvent(false)}} title={`Update Event Details`} subTitle={`Provide details of the upcoming event in the form below`} >
        <EditEventForm 
            facilities={facilitiesState?.facilities} 
            eventPayload={eventsState?.eventDetails}
            setEventPayload={(data)=>{setEventUpdatePayload(data)}}
            doUpdate={()=>updateEvent()}
        />
      </SlideOutModal>
    }

    {!eventsState.fetchingEvents && eventsState.events?.events?.length > 0 && 
          <SlideOutModal isOpen={creatingTicket} closeFunction={()=>{closeNewTicket()}} title={`Create a new ticket for ${eventsState?.eventDetails?.name}`} subTitle={`Provide details of the new ticket in the form below`} >
            <NewTicketForm 
              ticketPayload={ticketPayload}
              setTicketPayload={(data)=>{setTicketPayload(data)}}
              forEvent={true}
            />
          </SlideOutModal>
      }
</UserLayout>
  )
}

export default Event
